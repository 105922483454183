import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
   
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Booking, getAllBookings } from "../../../slices/bookedKeys/thunk";
import { FormatedDate, FormatedTime } from "../../../helpers/date_helper";
import {
  resetBooking,
  resetStatus,
  setBookingStatus,
  setPageBooking,
} from "../../../slices/bookedKeys/reducer";

const BookedKeys = ({ header = true }) => {
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [allBookings, setAllBookings] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  const startDateRes = useSelector(
    (state) => state.BookedSlice.startBookingTime
  );
  const endDateRes = useSelector((state) => state.BookedSlice.endBookingTime);
  const booikingKeywordRes = useSelector(
    (state) => state.BookedSlice.bookingKeyword
  );

  const getAllBookingsRes = useSelector(
    (state) => state.BookedSlice.allBookings
  );
  const loading = useSelector((state) => state.BookedSlice.loading);

  const fetchData = (val) => {
    dispatch(getAllBookings(val));
  };

  const bookingstatusRes = useSelector((state) => state.BookedSlice.status);
  const bookingRes = useSelector((state) => state.BookedSlice.booking);
  const pageRes = useSelector((state) => state.BookedSlice.pageBook);

  useEffect(() => {
    return () => dispatch(resetBooking());
  }, []);

  useEffect(() => {
    return () => dispatch(resetStatus());
  }, []);

  useEffect(() => {
    let params = {};
    params.page = pageRes;
    params.limit = 10;
    params.status = bookingstatusRes;

    fetchData(params);
  }, [bookingRes])

  useEffect(() => {
    let params = {};
    params.page = pageRes;
    params.limit = 10;
    params.status = bookingstatusRes;
    params.startTime = startDateRes;
    params.endTime = endDateRes;
    params.keyName = booikingKeywordRes;
    fetchData(params);
  }, [pageRes, bookingstatusRes, booikingKeywordRes, endDateRes]);

  useEffect(() => {
    if (getAllBookingsRes && getAllBookingsRes.success) {
      setAllBookings(getAllBookingsRes?.data);
    }
  }, [getAllBookingsRes]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (type !== "all") {
        dispatch(setPageBooking(1));
        dispatch(setBookingStatus(type));
      } else {
        dispatch(setPageBooking(1));
        dispatch(setBookingStatus(""));
      }
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const handleOrderClicks = () => {
    setOrder("");
    setIsEdit(false);
    toggle();
  };

  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const handleBooking = (id, status) => {
    let params = {
      bookingId: id,
      status: status,
    };
    dispatch(Booking(params));
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Column
  const columns = useMemo(
    () => [
      {
        header: "Key Name",
        accessorKey: "key",
        // enableColumnFilter: false,
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Link
              to={`/bookedkeys/${cell.row.original._id}`}
              className="text-primary d-inline-block"
            >
              {value == null ? (
                <span className="badge bg-danger text-white">Deleted</span>
              ) : (
                value.name
              )}
            </Link>
          );
        },
      },
      {
        header: "Start Time",
        accessorKey: "startTime",
        cell: (cell) => (
          <>
            {FormatedDate(cell.getValue())} {"<>"}
            {FormatedTime(cell.getValue())}
          </>
        ),
      },
      {
        header: "End Time",
        accessorKey: "endTime",
        cell: (cell) => (
          <>
            {" "}
            {FormatedDate(cell.getValue())} {"<>"}
            {FormatedTime(cell.getValue())}
          </>
        ),
      },

      {
        header: "Type",
        accessorKey: "type",
        cell: (cell) => <>{cell.getValue()}</>,
      },
      {
        header: "User Name",
        accessorKey: "user",
        cell: (cell) => (
          <>{cell.getValue()?.firstName + " " + cell.getValue()?.lastName}</>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: (cell) => {
          const status = cell.getValue();
          const getStatusStyle = (status) => {
            switch (status) {
              case "cancelled":
                return "bg-danger text-white";
              case "rejected":
                return "bg-danger text-white";
              case "booked":
                return "bg-success text-white";
              case "pending_approval":
                return "bg-warning text-dark";
              default:
                return "";
            }
          };
          return (
            <div className={`p-2 badge ${getStatusStyle(status)}`}>
              {status}
            </div>
          );
        },
      },

      {
        header: "Action",
        cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <UncontrolledDropdown>
                <DropdownToggle
                  href="#"
                  className="btn btn-soft-secondary btn-sm"
                  tag="button"
                >
                  <i className="ri-more-fill" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    tag={Link}
                    to={`/bookedKeys/${cellProps.row.original._id}`}
                  >
                    View
                  </DropdownItem>
                  {cellProps.row.original.status !== "cancelled" && (
                    <DropdownItem
                      tag={Link}
                      onClick={() =>
                        handleBooking(cellProps.row.original._id, "cancelled")
                      }
                    >
                      Cancel
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          );
        },
      },
    ],
    [handleOrderClick, checkedAll]
  );

  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };
  document.title = "Swipe panel";

  return (
    <div className={header ? "page-content" : ""}>
      <Container fluid>
        {header && <BreadCrumb title="Booked Keys" pageTitle="" />}
        <Row>
          <Col lg={12}>
            <Card id="allBookings">
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Bookings
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2", "rejected");
                        }}
                        href="#"
                      >
                        <i className="ri-thumb-down-line me-1 align-bottom"></i>{" "}
                        Rejected
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3", "cancelled");
                        }}
                        href="#"
                      >
                        <i className="ri-close-circle-line me-1 align-bottom"></i>{" "}
                        Cancelled
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4", "pending_approval");
                        }}
                        href="#"
                      >
                        <i className="ri-time-line me-1 align-bottom"></i>{" "}
                        Pending
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "5" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("5", "booked");
                        }}
                        href="#"
                      >
                        <i className="ri-bookmark-line me-1 align-bottom"></i>{" "}
                        Booked
                      </NavLink>
                    </NavItem>
                  </Nav>

                  {loading ? (
                    <div className="d-flex justify-content-center my-3">
                      <Spinner
                        animation="border"
                        role="status"
                        className="text-success"
                        style={{ width: "2rem", height: "2rem" }}
                      />
                    </div>
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={allBookings || []}
                      total={getAllBookingsRes.totalBookings}
                      isGlobalFilter={true}
                      isBookingFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                      handleOrderClick={handleOrderClicks}
                      SearchPlaceholder="Search Bookings.."
                      pageRes={pageRes}
                    />
                  )}
                </div>
                <ToastContainer closeButton={true} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BookedKeys;
