import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    getNukiAuthUrl,getNukiAuthUrlCode,getNukiConnection,getAllSmartLockData,disconnectNuki
} from "../../helpers/fakebackend_helper";

export const NukiRedirectUrl = createAsyncThunk(
  "nuki/NukiUrl",
  async (params) => {
    try {
      const response = getNukiAuthUrl(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const NukiCalbackUrlWithCode = createAsyncThunk(
  "nuki/NukiUrlCode",
  async (code) => {
    try {
      const response = getNukiAuthUrlCode(code);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const NukiConnectedOrNot = createAsyncThunk(
  "nuki/NukiConnection",
  async (data) => {
    try {
      const response = getNukiConnection(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const AllSmartLocks = createAsyncThunk(
  "nuki/AllSmartLocks",
  async (data) => {
    try {
      const response = getAllSmartLockData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const Diconnect = createAsyncThunk(
  "nuki/Diconnect",
  async (data) => {
    try {
      const response = disconnectNuki(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
