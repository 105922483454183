import { createSlice } from "@reduxjs/toolkit";
import {
  NukiRedirectUrl,
  NukiCalbackUrlWithCode,
  NukiConnectedOrNot,
  AllSmartLocks,
  Diconnect,
} from "./thunk";

export const initialState = {
  nukiUrl: [],
  nukiUrlCode: "",
  nukiConnection: [],
  allSmartLocks: [],
  diconnect: {},
  loading: false,
  error: null,
};

const NukiSlice = createSlice({
  name: "nuki",
  initialState,
  reducers: {
    resetAllSmartLocks(state) {
      state.allSmartLocks = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(NukiRedirectUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(NukiRedirectUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.nukiUrl = action.payload;
      })
      .addCase(NukiRedirectUrl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        alert(action.error.message);
      })
      .addCase(NukiCalbackUrlWithCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(NukiCalbackUrlWithCode.fulfilled, (state, action) => {
        state.loading = false;
        state.nukiUrlCode = action.payload;
      })
      .addCase(NukiCalbackUrlWithCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        alert(action.error.message);
      })
      .addCase(NukiConnectedOrNot.pending, (state) => {
        state.loading = true;
      })
      .addCase(NukiConnectedOrNot.fulfilled, (state, action) => {
        state.loading = false;
        state.nukiConnection = action.payload;
      })
      .addCase(NukiConnectedOrNot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        alert(action.error.message);
      })
      .addCase(AllSmartLocks.pending, (state) => {
        state.loading = true;
      })
      .addCase(AllSmartLocks.fulfilled, (state, action) => {
        state.loading = false;
        state.allSmartLocks = action.payload;
      })
      .addCase(AllSmartLocks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        alert(action.error.message);
      })
      .addCase(Diconnect.pending, (state) => {
        state.loading = true;
      })
      .addCase(Diconnect.fulfilled, (state, action) => {
        state.loading = false;
        state.diconnect = action.payload;
      })
      .addCase(Diconnect.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        alert(action.error.message);
      });
  },
});

export const { resetAllSmartLocks } = NukiSlice.actions;

export default NukiSlice.reducer;
