import React, { useEffect, useMemo, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line, RiEyeLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import DeleteModal from "../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { LuClipboardEdit } from "react-icons/lu";
import NoteModal from "../components/NoteModal";
import TableComponent from "../components/TableComponent";
import {
  deleteKeyGroup,
  getKeyGroupById,
} from "../../../slices/keyGroups/thunk";
import {
  resetaddNote,
  resetdeleteKeyNote,
  resetEditNote,
} from "../../../slices/swipeNotes/reducer";
import { deleteKeyNote } from "../../../slices/swipeNotes/thunk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetDeleteKeyGroup } from "../../../slices/keyGroups/reducer";
import { getAllKeys, RemoveKeysFromKeyGroup } from "../../../slices/keys/thunk";
import {
  resetAddKeysInKeyGroup,
  resetRemoveKeysFromKeyGroup,
} from "../../../slices/keys/reducer";

function ViewKeyGroup() {
  const [note, setNote] = useState(false);
  const [seeNote, setSeeNote] = useState(false);
  const [addKeys, setAddKeys] = useState(false);
  const [noteData, setNoteData] = useState({});
  const [editNote, setEditNote] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [removeKeyId, setRemoveKeyId] = useState(null);
  const [noteDeleteId, setNoteDeleteId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { ID } = useParams();
  const getOneKeyRes = useSelector((state) => state.KeyGroupsSlice.getKeyGroup);

  const addNoteRes = useSelector((state) => state.SwipeNotesSlice.addNote);
  const editNoteRes = useSelector((state) => state.SwipeNotesSlice.editNote);
  const deleteNoteRes = useSelector(
    (state) => state.SwipeNotesSlice.deleteKeyNote
  );

  const loading = useSelector((state) => state.KeySlice.loading);
  const AllKeysdataRes = useSelector((state) => state.KeySlice.allKeys.data);
  const AddKeysInKeyGroupRes = useSelector(
    (state) => state.KeySlice.addKeysInKeyGroup
  );
  const RemoveKeysFromKeyGroupRes = useSelector(
    (state) => state.KeySlice.removeKeysFromKeyGroup
  );

  useEffect(() => {
    if (addNoteRes?.success) {
      dispatch(getKeyGroupById(ID));
      dispatch(resetaddNote(ID));
      toast(addNoteRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    } else if (editNoteRes.success) {
      dispatch(getKeyGroupById(ID));
      dispatch(resetEditNote());
      toast(editNoteRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    } else if (deleteNoteRes.success) {
      dispatch(getKeyGroupById(ID));
      dispatch(resetdeleteKeyNote(ID));
      toast(deleteNoteRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    } else if (AddKeysInKeyGroupRes.success) {
      dispatch(getKeyGroupById(ID));
      dispatch(resetAddKeysInKeyGroup());
      toast(AddKeysInKeyGroupRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    } else if (RemoveKeysFromKeyGroupRes.success) {
      dispatch(getKeyGroupById(ID));
      dispatch(resetRemoveKeysFromKeyGroup());
      toast(RemoveKeysFromKeyGroupRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    } else if (ID !== null) {
      dispatch(getKeyGroupById(ID));
      dispatch(getAllKeys());
    }
  }, [
    addNoteRes.success,
    editNoteRes.success,
    deleteNoteRes.success,
    AddKeysInKeyGroupRes.success,
    RemoveKeysFromKeyGroupRes.success,
  ]);

  function EditNote(note) {
    setEditNote(!editNote);
    setNoteData(note);
  }

  const deleteNote = (note) => {
    setDeleteModal(true);
    setNoteDeleteId(note?._id);
  };

  const handleOpen = (id) => {
    setDeleteId(ID);
    setDeleteModal(true);
  };

  function SeeNote(note) {
    setSeeNote(!seeNote);
    setNoteData(note);
  }

  const removeKey = (id) => {
    setRemoveKeyId(id);
    setDeleteModal(true);
  };

  const handleDeleteKey = () => {
    if (deleteId !== null) {
      dispatch(deleteKeyGroup(deleteId));
      setDeleteModal(false);
      navigate("/all-keygroups");
      setDeleteId(null);
    } else if (noteDeleteId !== null) {
      dispatch(deleteKeyNote(noteDeleteId));
      setDeleteModal(false);
      navigate(`/view-keygroup/${ID}`);
      setNoteDeleteId(null);
    } else if (removeKeyId !== null) {
      dispatch(RemoveKeysFromKeyGroup({ keyId: removeKeyId }));
      setDeleteModal(false);
      navigate(`/view-keygroup/${ID}`);
      setRemoveKeyId(null);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Key Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          const name = getValue();
          const keyId = row.original._id;

          return (
            <div>
              <Link to={`/view-key/${keyId}`} key={keyId}>
                {name}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          const email = getValue();
          const keyId = row.original._id;

          return (
            <div>
              <Link to={`/view-key/${keyId}`} key={keyId}>
                {email}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Phone Number",
        accessorKey: "phoneNumber",
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          const phoneNumber = getValue();
          const keyId = row.original._id;

          return (
            <div>
              <Link to={`/view-key/${keyId}`} key={keyId}>
                {phoneNumber}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Action",
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          const keyId = row.original._id;

          return (
            <div>
              <Button
                color="black"
                className="p-0 m-0 border-0 bg-transparent"
                onClick={() => removeKey(keyId)}
              >
                <RiDeleteBin6Line className="me-2" />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="page-content">
        <ToastContainer autoClose={2000} limit={1} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteKey}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody className="bg-white">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <h6 className="card-title mb-0 flex-grow-1 fw-bold">
                      {getOneKeyRes?.data?.name}
                    </h6>
                  </div>
                  <div className=" d-flex gap-2 justify-content-end ">
                    <Link to={`/edit-keygroup/${ID}`}>
                      <Button
                        color="secondary"
                        variant="outline"
                        className="edit-icon-button white-button"
                      >
                        <FaRegEdit />
                        <span className="ms-1">Edit Keychain</span>
                      </Button>
                    </Link>
                    <div className="">
                      {ID && (
                        <Button
                          color="danger"
                          variant="outline"
                          className="w-100 d-flex align-items-center justify-content-center white-button hover-red"
                          onClick={() => handleOpen()}
                        >
                          <RiDeleteBin6Line className="me-1" />
                          Delete Keychain
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Notes</h5>
              <Button
                color="secondary"
                variant="outline"
                className="edit-icon-button px-3 py-1 mb-1 blue-button"
                onClick={() => setNote(true)}
              >
                Add
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {getOneKeyRes?.data &&
              getOneKeyRes?.data?.note.map((note, index) => (
                <div key={note._id} className="d-flex align-items-center gap-2 mb-2">
                  <span className="fw-bold">{index + 1}</span>
                  <textarea
                    type="text"
                    value={note?.content}
                    rows={1}
                    readOnly
                    className="form-control"
                    style={{
                      flex: 1,
                      height: "auto",
                      maxHeight: "80px",
                    }}
                  />
                  <span>
                    <RiEyeLine
                      size={18}
                      onClick={() => SeeNote(note)}
                      className="me-1 ho cursor-pointer"
                    />
                  </span>
                  <span>
                    <LuClipboardEdit
                      size={18}
                      onClick={() => EditNote(note)}
                      className="me-1 ho cursor-pointer"
                    />
                  </span>
                  <span>
                    <RiDeleteBin6Line
                      onClick={() => deleteNote(note)}
                      size={18}
                      className="me-1 cursor-pointer"
                    />
                  </span>
                </div>
              ))}
            {note && (
              <NoteModal
                note={note}
                setNote={setNote}
                ID={ID}
                name={"keyGroup"}
                label={"Add Note"}
              />
            )}
            {editNote && (
              <NoteModal
                note={editNote}
                setNote={setEditNote}
                label={"Edit Note"}
                noteData={noteData}
              />
            )}
            {seeNote && (
              <NoteModal
                note={seeNote}
                setNote={setSeeNote}
                label={"See Note"}
                noteData={noteData}
              />
            )}
            {addKeys && (
              <NoteModal
                note={addKeys}
                setNote={setAddKeys}
                name={"keyGroup"}
                ID={ID}
                label={"Add Keys"}
                noteData={AllKeysdataRes}
              />
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5 className="card-title mb-0"> Images</h5>
          </CardHeader>
          <CardBody>
            <FormGroup style={{ backgroundColor: "white", marginBottom: 3 }}>
              {getOneKeyRes &&
                getOneKeyRes?.data?.mediaIds?.map((img) => (
                  <figure key={img.url} className="figure mb-0">
                    <img
                      src={img?.url}
                      className="figure-img img-fluid rounded me-2"
                      style={{ maxWidth: "200px", width: "100%" }}
                      alt="..."
                    />
                  </figure>
                ))}
            </FormGroup>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-0">List of all Keys:</h4>
              <div>
                {" "}
                <Button
                  color="secondary"
                  variant="outline"
                  className="edit-icon-button px-3 py-1 mb-1 blue-button"
                  onClick={() => setAddKeys(true)}
                >
                  Add Keys in this keychain
                </Button>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <TableComponent
              data={getOneKeyRes?.data?.keyList || []}
              columns={columns}
              total={getOneKeyRes?.data?.keyList?.length}
              customPageSize={5}
              isGlobalFilter={true}
              divClass="table-responsive mb-1"
              tableClass="mb-0 align-middle table-borderless"
              theadClass="table-light text-muted"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ViewKeyGroup;
