import { createSlice } from "@reduxjs/toolkit";
import { getAllKeys } from "./thunk";

export const initialState = {
  allKeys: [],
  loading: false,
  page: 1,
  error: null,
};

const RequestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    setPageRequest(state, action) {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllKeys.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllKeys.fulfilled, (state, action) => {
        state.allKeys = action.payload;
        state.loading = false;
      })
      .addCase(getAllKeys.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});
export const { resetError, setPageRequest } = RequestSlice.actions;

export default RequestSlice.reducer;
