import React, { useEffect, useState } from "react";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { formatTermDate } from "../../helpers/date_helper";
import { useDispatch, useSelector } from "react-redux";
import {
  createLongBooking,
  getLongSlotsThuk,
} from "../../slices/bookedKeys/thunk";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LongBooking() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [disabledDates, setDisabledDates] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const navigate = useNavigate();

  const allDates = useSelector((state) => state.BookedSlice.getLongSlots.data);
  const getOneKeyRes = useSelector((state) => state.KeySlice.oneKey);

  const bookingRes = useSelector((state) => state.BookedSlice.longBooking);

  const dispatch = useDispatch();
  const { ID } = useParams();

  const getAllDates = () => {
    dispatch(getLongSlotsThuk(ID));
  };

  useEffect(() => {
    if (bookingRes && bookingRes.success) {
      toast(bookingRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });

      const timer = setTimeout(() => {
        navigate("/book-a-key/congrats");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [bookingRes, navigate]);

  useEffect(() => {
    getAllDates();
  }, []);

  const handleDateChange = (date) => {
    const newStrtDate = formatTermDate(date[0]);
    setStartDate(newStrtDate);
    const newEndDate = formatTermDate(date[1]);
    setEndDate(newEndDate);
  };

  const handleBook = () => {
    if (!name || !email || !phoneNumber || !startDate) {
      toast.error("All fields are required", {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
      return;
    }

    let data = {
      keyId: ID,
      fromDate: startDate,
      toDate: endDate ? endDate : startDate,
      name: name,
      email: email,
      phoneNumber: phoneNumber,
    };
    dispatch(createLongBooking(data));
  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 90);
  const minDate = new Date();

  useEffect(() => {
    if (allDates) {
      const bookedDates = Object?.keys(allDates).filter(
        (date) => allDates[date] === "Booked"
      );
      setDisabledDates(bookedDates);
    }
  }, [allDates]);

  return (
    <>
      <React.Fragment>
        <ToastContainer autoClose={2000} limit={1} />
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className=" mt-sm-5 mb-2 text-white-50">
                  <img
                    style={{ width: "100px", height: "auto" }}
                    className="object-fit-contain"
                    src="/swiplogo.png"
                    alt="Icon"
                  />
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={12}>
                <Card className="mt-2 ">
                  <CardBody className="p-2">
                    <div className="mt-2">
                      <h5 className="text-primary">
                        Book Key {getOneKeyRes?.data?.key?.name} For Long Term !
                      </h5>
                    </div>
                    <div>
                      <div className="p-2">
                        <Row>
                          <Col lg={12}>
                            <div className="mt-3">
                              <Label className="form-label mb-0">
                                Select Date
                              </Label>
                              <Flatpickr
                                className="form-control"
                                options={{
                                  inline: true,
                                  mode: "range",
                                  dateFormat: "Y-m-d",
                                  disable: disabledDates,
                                  maxDate: maxDate,
                                  minDate: minDate,
                                }}
                                onChange={(e) => handleDateChange(e)}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mt-2">
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="name-input"
                                >
                                  Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="name-input"
                                  placeholder="Enter Your Name"
                                  name="name"
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="mt-2">
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="email-input"
                                >
                                  Email
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="email-input"
                                  placeholder="Enter Your Email"
                                  name="email"
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="mt-2">
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="phone-input"
                                >
                                  Mobile Number
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="phone-input"
                                  placeholder="Enter Your Number"
                                  name="phoneNumber"
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <Button onClick={handleBook}>Book Now</Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default LongBooking;
