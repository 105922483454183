import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";

const token = JSON.parse(localStorage.getItem("authUser") || '{}');

const Congratulation = () => {
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-2">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mb-2 text-white-50">
                  <img
                    style={{ width: "100px", height: "auto" }}
                    className="object-fit-contain"
                    src="/swiplogo.png"
                    alt="Icon"
                  />
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4 card-bg-fill">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h2 className="text-success">CONGRATULATIONS</h2>
                      <h2>🎉🎉🎉🎉🎉 </h2>
                      <h4 className="mt-3 text-success">
                        You Have Successfully Booked
                      </h4>
                    </div>

                    {token && token.success ? (
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          To View Your Booking Click{" "}
                          <Link
                            to="/bookedkeys"
                            className="fw-semibold text-primary text-decoration-underline"
                          >
                            Here
                          </Link>
                        </p>
                      </div>
                    ) : (
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          To View Your Booking Please SignIn?{" "}
                          <Link
                            to="/login"
                            className="fw-semibold text-primary text-decoration-underline"
                          >
                            SignIn
                          </Link>
                        </p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Congratulation;
