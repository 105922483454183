import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllKeysData,
  getOneKeyData,
  AddKeyData,
  UpdateKeyByIdData,
  deleteOneKeyData,
  AddKeysInKeyGroupData,
  RemoveKeysFromKeyGroupData,
  addKeyFavData,
  removeKeyFavData,
} from "../../helpers/fakebackend_helper";

export const getAllKeys = createAsyncThunk(
  "keys/getAllKeysData",
  async (params) => {
    try {
      const response = getAllKeysData(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getOneKey = createAsyncThunk("keys/getOneKey", async (id) => {
  try {
    const response = getOneKeyData(id);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteKey = createAsyncThunk("keys/deleteKey", async (id) => {
  try {
    const response = deleteOneKeyData(id);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateOneKey = createAsyncThunk(
  "keys/updateOneKey",
  async ({ values, ID }) => {
    try {
      const response = UpdateKeyByIdData(values, ID);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const AddKey = createAsyncThunk("keys/AddKey", async (data) => {
  try {
    const response = AddKeyData(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const AddKeysInKeyGroup = createAsyncThunk(
  "keys/AddKeysInKeyGroup",
  async (data) => {
    try {
      const response = AddKeysInKeyGroupData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const AddFavKey = createAsyncThunk(
  "keys/AddFavKey",
  async(data) =>{
    try {
      const response = addKeyFavData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const RemoveFavKey = createAsyncThunk(
  "keys/RemoveFavKey",
  async(data) =>{
    try {
      const response = removeKeyFavData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const RemoveKeysFromKeyGroup = createAsyncThunk(
  "keys/RemoveKeysFromKeyGroupData",
  async (data) => {
    try {
      const response = RemoveKeysFromKeyGroupData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
