import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";

const WeekdaysCheckbox = ({name ,selectedWeekdays,selectedData}) => {
  const [selectedDays, setSelectedDays] = useState(selectedData || []);

  const weekdays = [
    { full: "Monday", short: "Mon" },
    { full: "Tuesday", short: "Tue" },
    { full: "Wednesday", short: "Wed" },
    { full: "Thursday", short: "Thu" },
    { full: "Friday", short: "Fri" },
    { full: "Saturday", short: "Sat" },
    { full: "Sunday", short: "Sun" },
  ];

  const handleCheckboxChange = (day) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };
  
  useEffect(() =>{
    if(selectedDays.length > 0){
      selectedWeekdays(selectedDays)
    }
  },[selectedDays])

  return (
    <div className="d-flex gap-2">
      {weekdays.map((day) => (
        <div key={day.full}>
          <Input
            type="checkbox"
            className="btn-check"
            checked={selectedDays.includes(day.full)}
            id={`${name}-${day.full}`}
            onChange={() => handleCheckboxChange(day.full)}
          />
          <Label
            className={`btn btn-outline-primary material-shadow rounded-circle d-flex justify-content-center align-items-center weekday-circle ${
              selectedDays.includes(day.full) ? "selected" : ""
            }`}
            for={`${name}-${day.full}`}
          >
            {day.short}
          </Label>
        </div>
      ))}
    </div>
  );
};

export default WeekdaysCheckbox;
