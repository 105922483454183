import React from "react";
import { Col, Container, Row } from "reactstrap";

const About = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>About</Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default About;
