import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { FormatedTime, formatTermDate } from "../../helpers/date_helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createShortBooking,
  getShortSlotsThuk,
} from "../../slices/bookedKeys/thunk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetBookingError } from "../../slices/bookedKeys/reducer";

function ShortBooking() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timings, setTimings] = useState([]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const handleChange = (value) => {
    parseSlotData(value);
  };

  const allTimings = useSelector((state) => state.BookedSlice.getShortSlots);
  const getOneKeyRes = useSelector((state) => state.KeySlice.oneKey);

  const bookingRes = useSelector((state) => state.BookedSlice.shortBooking);
  const bookingErr = useSelector((state) => state.BookedSlice.error);

  const navigate = useNavigate();

  useEffect(() => {
    if (bookingRes) {
      if (bookingRes.success) {
        toast.success(bookingRes.message, {
          position: "top-right",
          hideProgressBar: false,
          className: "bg-success text-white",
          progress: undefined,
          toastId: "",
        });

        const timer = setTimeout(() => {
          navigate("/book-a-key/congrats");
        }, 1000);

        return () => clearTimeout(timer);
      } else if (bookingErr) {
        toast.error(bookingErr, {
          position: "top-right",
          hideProgressBar: false,
          className: "bg-danger text-white",
          progress: undefined,
          toastId: "",
        });
      }
    }
    dispatch(resetBookingError());
  }, [bookingRes, navigate, bookingErr]);

  const handleStartShortDateChange = (date) => {
    const newDate = formatTermDate(date[0]);
    let timingsData = allTimings?.data[newDate] || [];
    setTimings(timingsData);
  };

  const dispatch = useDispatch();
  const { ID } = useParams();

  const getAllTimings = () => {
    dispatch(getShortSlotsThuk(ID));
  };

  useEffect(() => {
    getAllTimings();
  }, []);

  const parseSlotData = (data) => {
    const [startTime, endTime] = data.split(" - ");
    setStart(startTime);
    setEnd(endTime);
  };

  const handleBook = () => {
    if (!name || !email || !phoneNumber || !start || !end) {
      toast.error("All fields are required", {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
      return;
    }

    let data = {
      keyId: ID,
      startTime: start,
      endTime: end,
      name: name,
      email: email,
      phoneNumber: phoneNumber,
    };
    dispatch(createShortBooking(data));
  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 90);
  const minDate = new Date();

  const enabledDates = allTimings?.data
    ? Object.keys(allTimings.data).filter(
        (date) => allTimings.data[date].length > 0
      )
    : [];

  return (
    <React.Fragment>
      <ToastContainer autoClose={2000} limit={1} />
      <div className="auth-page-content mt-lg-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className=" mb-2 text-white-50">
                <img
                  style={{ width: "100px", height: "auto" }}
                  className="object-fit-contain"
                  src="/swiplogo.png"
                  alt="Icon"
                />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card className="mt-2">
                <CardBody className="p-2">
                  <div className=" mt-2">
                    <h5 className="text-primary">
                      Book Key {getOneKeyRes?.data?.key?.name} For Short Term !
                    </h5>
                  </div>
                  <div>
                    <div className="p-2">
                      <Row>
                        <Col lg={10}>
                          <div className="mt-3">
                            <Label className="form-label mb-0">
                              Select Date
                            </Label>
                            <Flatpickr
                              className="form-control"
                              options={{
                                inline: true,
                                dateFormat: "Y-m-d",
                                minDate: minDate,
                                enable: enabledDates,
                                maxDate: maxDate,
                              }}
                              onChange={(e) => handleStartShortDateChange(e)}
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="mt-5 d-flex flex-row flex-lg-column gap-2">
                            {timings?.length > 0 ? (
                              timings.map((time, index) => (
                                <div
                                  key={index}
                                  className="hstack gap-2 flex-wrap"
                                >
                                  <Input
                                    type="radio"
                                    className="btn-check"
                                    name="options"
                                    id={`option${index}`}
                                    value={`${time?.start} - ${time?.end}`}
                                    disabled={time?.available === false}
                                    onChange={(e) =>
                                      handleChange(e.target.value)
                                    }
                                  />
                                  <Label
                                    className="btn btn-secondary"
                                    for={`option${index}`}
                                  >
                                    {FormatedTime(time?.start)} -{" "}
                                    {FormatedTime(time?.end)}
                                  </Label>
                                </div>
                              ))
                            ) : (
                              <h5 style={{ color: "red" }} className="mb-3">
                                NO SLOTS AVAILABLE FOR THIS DAY
                              </h5>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mt-2">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="name-input"
                              >
                                Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="name-input"
                                placeholder="Enter Your Name"
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="email-input"
                              >
                                Email
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="email-input"
                                placeholder="Enter Your Email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="phone-input"
                              >
                                Mobile Number
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="phone-input"
                                placeholder="Enter Your Number"
                                name="phoneNumber"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <Button onClick={handleBook}>Book Now</Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ShortBooking;
