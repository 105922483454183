import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard Ecommerce   Swipe
import DashboardEcommerceReducer from "./dashboardEcommerce/reducer";
 
//keys
import KeySlice from "./keys/reducer"

//Nuki
import NukiSlice from "./nuki/reducer"

//Requests
import RequestsSlice from "./requests/reducer"

//KeyGroups
import KeyGroupsSlice from "./keyGroups/reducer"

//Booked Keys
import BookedSlice from "./bookedKeys/reducer";
//SwipeNotes 
import SwipeNotesSlice from "./swipeNotes/reducer";


//About
import AboutSlice from "./about/reducer"


const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  DashboardEcommerce: DashboardEcommerceReducer,
  KeySlice: KeySlice,
  KeyGroupsSlice:KeyGroupsSlice,
  RequestsSlice:RequestsSlice,
  BookedSlice:BookedSlice,
  SwipeNotesSlice:SwipeNotesSlice,
  AboutSlice:AboutSlice,
  NukiSlice:NukiSlice,
});

export default rootReducer;
