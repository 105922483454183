import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllKeysData,
  booking,
  getShortSlots,
  getLongSlots,
  longBooking,
  shortBooking,
  getAllBokingsData,
  getOneBokingData,
  getAllSmartlocksData,
  getLockStatusData,
  lockSmartLockData,
  unlockSmartLockData,
  getSmartLockLogsData,
  lockByBookingData,
  unlockByBookingData,
  getAllRequestsData,
  getOneRequestData,
  BookingData,
} from "../../helpers/fakebackend_helper";

export const getAllKeys = createAsyncThunk(
  "bookedKeys/getAllKeysData",
  async (params) => {
    try {
      const response = getAllKeysData(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllBookings = createAsyncThunk(
  "bookedKeys/getAllBookings",
  async (data) => {
    try {
      const response = getAllBokingsData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const Booking = createAsyncThunk(
  "bookedKeys/Booking",
  async (data) => {
    try {
      const response = BookingData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllRequests = createAsyncThunk(
  "bookedKeys/getAllRequests",
  async (data) => {
    try {
      const response = getAllRequestsData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getOneRequest = createAsyncThunk(
  "bookedKeys/getOneRequest",
  async (data) => {
    try {
      const response = getOneRequestData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getOneBooking = createAsyncThunk(
  "bookedKeys/getOneBooking",
  async (params) => {
    try {
      const response = getOneBokingData(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getShortSlotsThuk = createAsyncThunk(
  "bookedKeys/getShortSlotsThuk",
  async (params) => {
    try {
      const response = getShortSlots(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getLongSlotsThuk = createAsyncThunk(
  "bookedKeys/getLongSlotsThuk",
  async (data) => {
    try {
      const response = getLongSlots(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createShortBooking = createAsyncThunk(
  "bookedKeys/createShortBooking",
  async (data) => {
    try {
      const response = shortBooking(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createLongBooking = createAsyncThunk(
  "bookedKeys/createLongBooking",
  async (data) => {
    try {
      const response = longBooking(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

//Lock Unlock
export const getAllSmartLocks = createAsyncThunk(
  "bookedKeys/getAllSmartLocks",
  async (data) => {
    try {
      const response = getAllSmartlocksData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getLockStatus = createAsyncThunk(
  "bookedKeys/getLockStatus",
  async (data) => {
    try {
      const response = getLockStatusData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const lockSmartLock = createAsyncThunk(
  "bookedKeys/lockSmartLock",
  async (data) => {
    try {
      const response = lockSmartLockData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const unlockSmartLock = createAsyncThunk(
  "bookedKeys/unlockSmartLock",
  async (data) => {
    try {
      const response = unlockSmartLockData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSmartLockLogs = createAsyncThunk(
  "bookedKeys/getSmartLockLogs",
  async (data) => {
    try {
      const response = getSmartLockLogsData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const lockByBooking = createAsyncThunk(
  "bookedKeys/lockByBooking",
  async (data) => {
    try {
      const response = lockByBookingData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const unlockByBooking = createAsyncThunk(
  "bookedKeys/unlockByBooking",
  async (data) => {
    try {
      const response = unlockByBookingData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
