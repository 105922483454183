import axios from "axios";
import React, { useEffect, useState } from "react";
import { LuClipboardEdit } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Table,
} from "reactstrap";
import { BASE_URL } from "../../../helpers/url_helper";
import { useDispatch, useSelector } from "react-redux";
import {
  NukiConnectedOrNot,
  NukiRedirectUrl,
  NukiCalbackUrlWithCode,
  Diconnect,
} from "../../../slices/nuki/thunk";
import { Redirect, useNavigate } from "react-router-dom";
import DeleteModal from "../components/DeleteModal";

const ConnectedSmartLock = () => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const nukiUrlRes = useSelector((state) => state.NukiSlice.nukiUrl);

  const nukiCodeRes = useSelector((state) => state.NukiSlice.nukiUrlCode);

  const nukiConnectionRes = useSelector(
    (state) => state.NukiSlice.nukiConnection
  );
  const navigate = useNavigate();
  const loading = useSelector((state) => state.NukiSlice.loading);

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      try {
        const connected_or_not = await dispatch(NukiConnectedOrNot());
        if (!connected_or_not?.payload?.connected && code) {
          const params = { code: code };
          const res = await dispatch(NukiCalbackUrlWithCode(params));
          if (res?.payload?.success) {
            checkConnection();
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (nukiUrlRes?.success) {
      window.location.href = nukiUrlRes?.url;
    }
  }, [nukiUrlRes?.success]);

  const checkConnection = () => {
    dispatch(NukiConnectedOrNot());
  };

  useEffect(() => {
    if (nukiCodeRes?.success) {
      checkConnection();
    }
  }, [nukiUrlRes]);

  const getNukiData = async () => {
    dispatch(NukiRedirectUrl());
  };

  const handleDisconnect = () => {
    setDeleteModal(true);
  };

  const Disconnect = async () => {
    const res = await dispatch(Diconnect());
    if (res?.payload?.success) {
      checkConnection();
    }
    setDeleteModal(false);
  };

  return (
    <>
      <DeleteModal
        show={deleteModal}
        name={"disconnect"}
        onDeleteClick={Disconnect}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Smart Lock</h5>
              {loading ? (
                <Spinner size="sm" className="me-2" />
              ) : (
                <>
                  {nukiConnectionRes && nukiConnectionRes.connected ? (
                    <span className="badge text-uppercase bg-success px-3 py-2">
                      Connected
                    </span>
                  ) : (
                    <Button
                      color="secondary"
                      outline
                      className="edit-icon-button px-3 py-1 mb-1 blue-button"
                      onClick={getNukiData}
                    >
                      Connect Nuki Account
                    </Button>
                  )}
                </>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <Table className="mb-4">
              {nukiConnectionRes?.accountInfo?.name && (
                <thead>
                  <tr>
                    <th>NAME :</th>
                    <th>{nukiConnectionRes?.accountInfo?.name}</th>
                  </tr>
                </thead>
              )}
              {nukiConnectionRes?.accountInfo?.email && (
                <thead>
                  <tr>
                    <th>Email Address :</th>
                    <th>{nukiConnectionRes?.accountInfo?.email}</th>
                  </tr>
                </thead>
              )}
            </Table>
            <div className="d-flex justify-content-end">
              <div className="flex-shrink-0">
                {nukiConnectionRes?.accountInfo && (
                  <Button
                    color="danger"
                    variant="outline"
                    onClick={handleDisconnect}
                    className="w-100 d-flex align-items-center justify-content-center white-button hover-red"
                  >
                    Disconnect
                  </Button>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ConnectedSmartLock;
