import React, { useEffect, useState, useCallback } from "react";
import { Col, Row, Spinner } from "reactstrap"; // Import Spinner
import Select, { components } from "react-select";
import { flattenProduct } from "../../../helpers/date_helper";
import Pagination from "../../../Components/Common/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getAllKeyGroups } from "../../../slices/keyGroups/thunk";
import debounce from "lodash.debounce";

const KeyChainSelector = ({ handleSelectedFnc, placeholder, selectedData }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");

    const keyGroupsRes = useSelector(
        (state) => state.KeyGroupsSlice.allKeyGroups.data
    );
    const loading = useSelector((state) => state.KeyGroupsSlice.loading);

    const perPageData = 10;
    const dispatch = useDispatch();

    const fetchKeys = (page, searchQuery) => {
        const params = {
            page,
            limit: perPageData,
            keyword: searchQuery,
        };
        dispatch(getAllKeyGroups(params));
    };

    useEffect(() => {
        fetchKeys(page, searchQuery);
    }, [page, searchQuery, dispatch]);

    const handleNextKeysApi = () => {
        setPage((val) => val + 1);
    };

    const handlePrevKeysApi = () => {
        setPage((val) => val - 1);
    };

    useEffect(() => {
        if (selectedData) {
            const data = flattenProduct([selectedData]);
            setSelectedOption(data);
        }
    }, [selectedData]);

    // Debounced input change handler
    const debouncedInputChange = useCallback(
        debounce((inputValue) => {
            setSearchQuery(inputValue.trim());
            setPage(1);
        }, 500),
        []
    );

    // Handle input change for the select component
    const handleInputChange = (newValue) => {
        debouncedInputChange(newValue);
        return newValue;
    };

    const customStyles = {
        singleValue: (styles) => ({
            ...styles,
            color: "black",
        }),
    };

    // Custom MenuList with conditional spinner
    const CustomMenuList = (props) => {
        return (
            <components.MenuList {...props}>
                {/* Conditionally render Spinner when loading */}
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "50px" }}>
                        <Spinner size="sm" color="primary" />
                    </div>
                ) : (
                    <>
                        {props.children}
                        <div className="pagination-wrapper mt-3 me-3">
                            <Pagination
                                data={flattenProduct(keyGroupsRes)}
                                currentPage={page}
                                setCurrentPage={setCurrentPage}
                                perPageData={perPageData}
                                handleNextKeysApi={handleNextKeysApi}
                                handlePrevKeysApi={handlePrevKeysApi}
                                customPageSize={10}
                                total={10}
                            />
                        </div>
                    </>
                )}
            </components.MenuList>
        );
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <div className="">
                        <Select
                            value={selectedOption}
                            placeholder={`${placeholder}`}
                            onChange={(selectedItem) => {
                                handleSelectedFnc(selectedItem);
                                setSelectedOption(selectedItem);
                            }}
                            options={flattenProduct(keyGroupsRes)}
                            classNamePrefix="js-example-disabled-multi mb-0"
                            styles={customStyles}
                            components={{ MenuList: CustomMenuList }}
                            onInputChange={handleInputChange}
                        />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default KeyChainSelector;
