import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";

const Pagination = ({
  data,
  currentPage,
  setCurrentPage,
  perPageData,
  handleNextKeysApi,
  handlePrevKeysApi,
  total,
  customPageSize,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / perPageData); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if (pageNumbers.length && pageNumbers.length < currentPage) {
      setCurrentPage(pageNumbers.length);
    }
  }, [pageNumbers.length, currentPage, setCurrentPage]);

  return (
    <>
      {data && data.length > 0 && (
        <Row className="align-items-center g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              Showing <span className="fw-semibold ms-1">{data.length}</span> of{" "}
              <span className="fw-semibold">{total}</span> Results
            </div>
          </div>

          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              {/* Previous Button */}
              <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                <button className="page-link" onClick={handlePrevKeysApi} disabled={currentPage === 1}>
                  Previous
                </button>
              </li>

              {/* Current Page Display */}
              <li className="page-item">
                <Link to="#" className="page-link active">
                  {currentPage}
                </Link>
              </li>

              {/* Next Button */}
              <li className={data.length < customPageSize ? "page-item disabled" : "page-item"}>
                <button className="page-link" onClick={handleNextKeysApi} disabled={data.length < customPageSize}>
                  Next
                </button>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </>
  );
};

export default Pagination;
