import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line, RiEyeLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import DeleteModal from "../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteKey, getOneKey } from "../../../slices/keys/thunk";
import { LuClipboardEdit } from "react-icons/lu";
import NoteModal from "../components/NoteModal";
import { FormatedDate } from "../../../helpers/date_helper";
import SmartButtonGroup from "../components/SmartButtonGroup";
import {
  lockSmartLock,
  unlockSmartLock,
} from "../../../slices/bookedKeys/thunk";
import {
  resetaddNote,
  resetdeleteKeyNote,
  resetEditNote,
} from "../../../slices/swipeNotes/reducer";
import { deleteKeyNote } from "../../../slices/swipeNotes/thunk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ViewKey() {
  const [note, setNote] = useState(false);
  const [noteData, setNoteData] = useState({});
  const [editNote, setEditNote] = useState(false);
  const [seeNote, setSeeNote] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [noteDeleteId, setNoteDeleteId] = useState(null);
  const [smartLockStatus, setSmartLockStatus] = useState([]);
  const [openerStatus, setOpenerStatus] = useState([]);

  const dispatch = useDispatch();

  const { ID } = useParams();
  const navigate = useNavigate();

  const getOneKeyRes = useSelector((state) => state.KeySlice.oneKey);
  const addNoteRes = useSelector((state) => state.SwipeNotesSlice.addNote);
  const editNoteRes = useSelector((state) => state.SwipeNotesSlice.editNote);
  const deleteNoteRes = useSelector(
    (state) => state.SwipeNotesSlice.deleteKeyNote
  );
  const loading = useSelector((state) => state.KeySlice.loading);

  const longTermOffDays = getOneKeyRes?.data?.key?.long_term_offDays;

  useEffect(() => {
    if (getOneKeyRes?.data) {
      const newOpenerStatus = {};
      const newSmartLockStatus = {};

      newOpenerStatus[getOneKeyRes.data?.key?._id] =
        getOneKeyRes.data?.openerStatus;
      newSmartLockStatus[getOneKeyRes.data?.key?._id] =
        getOneKeyRes.data?.smartLockStatus;
      setOpenerStatus(newOpenerStatus);
      setSmartLockStatus(newSmartLockStatus);
    }
  }, [getOneKeyRes]);

  const handleSmartLockButtonClick = async (buttonName, key) => {
    try {
      if (buttonName === "lock") {
        const res = await dispatch(lockSmartLock(key?.key?.smartlockId));
        if (res && res.payload && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?.key?._id] = "locked";
          setSmartLockStatus(newStatus);
        }
      } else if (buttonName === "unlock") {
        const res = await dispatch(unlockSmartLock(key?.key?.smartlockId));
        if (res && res.payload && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?.key?._id] = "unlocked";
          setSmartLockStatus(newStatus);
        }
      }
    } catch (error) {
      console.error("Error locking smart lock:", error);
    }
  };
  const handleOpenerButtonClick = async (buttonName, key) => {
    try {
      if (buttonName === "lock") {
        const res = await dispatch(lockSmartLock(key?.key?.openerId));
        if (res && res.payload && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?.key?._id] = "locked";
          setOpenerStatus(newStatus);
        }
      } else if (buttonName === "unlock") {
        const res = await dispatch(unlockSmartLock(key?.key?.openerId));
        if (res && res.payload && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?.key?._id] = "unlocked";
          setOpenerStatus(newStatus);
        }
      }
    } catch (error) {
      console.error("Error locking smart lock:", error);
    }
  };

  useEffect(() => {
    if (addNoteRes.success) {
      dispatch(getOneKey(ID));
      toast(addNoteRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      dispatch(resetaddNote(ID));
    } else if (editNoteRes.success) {
      dispatch(getOneKey(ID));
      toast(editNoteRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      dispatch(resetEditNote());
    } else if (deleteNoteRes.success) {
      dispatch(getOneKey(ID));
      toast(deleteNoteRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      dispatch(resetdeleteKeyNote(ID));
    } else {
      dispatch(getOneKey(ID));
    }
  }, [addNoteRes.success, editNoteRes.success, deleteNoteRes.success]);

  function EditNote(note) {
    setEditNote(!editNote);
    setNoteData(note);
  }

  function SeeNote(note) {
    setSeeNote(!seeNote);
    setNoteData(note);
  }

  const deleteNote = (note) => {
    setDeleteModal(true);
    setNoteDeleteId(note._id);
  };

  const handleOpen = () => {
    setDeleteId(ID);
    setDeleteModal(true);
  };

  const handleDeleteKey = () => {
    if (deleteId !== null) {
      dispatch(deleteKey(deleteId));
      setDeleteModal(false);
      navigate("/all-keys");
      setDeleteId(null);
    } else if (noteDeleteId !== null) {
      dispatch(deleteKeyNote(noteDeleteId));
      setDeleteModal(false);
      navigate(`/view-key/${ID}`);
      setNoteDeleteId(null);
    }
  };

  return (
    <div>
      <div className="page-content">
        <ToastContainer autoClose={2000} limit={1} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteKey}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody className="bg-white">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <h6 className="card-title mb-0 flex-grow-1 fw-bold">
                      {getOneKeyRes?.data?.key?.name}
                    </h6>
                  </div>
                  <div className=" d-flex gap-2 justify-content-end ">
                    {!loading ? (
                      <Link to={`/edit-key/${getOneKeyRes?.data?.key?._id}`}>
                        <Button
                          disabled={loading}
                          color="secondary"
                          variant="outline"
                          className="edit-icon-button white-button"
                        >
                          <FaRegEdit />
                          <span className="ms-1">Edit Key</span>
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        disabled
                        color="secondary"
                        variant="outline"
                        className="edit-icon-button white-button"
                      >
                        <FaRegEdit />
                        <span className="ms-1">Edit Key</span>
                      </Button>
                    )}

                    <div className="">
                      <Button
                        color="danger"
                        variant="outline"
                        className="w-100 d-flex align-items-center justify-content-center white-button hover-red"
                        onClick={() => handleOpen()}
                      >
                        <RiDeleteBin6Line className="me-1" />
                        Delete Key
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">Key Controls</h5>
          </CardHeader>
          <CardBody>
            <div className="col">
              <div className="row">
                {getOneKeyRes.data?.smartLockStatus && (
                  <div className="col-md-6 mt-sm-3">
                    <div className="d-flex gap-3 align-items-center">
                      <h6 className="font-weight-bold text-lg">Smart Lock </h6>
                      <h6 className="text-lg text-muted">
                        status:{" "}
                        <span className="orange-icon">
                          {getOneKeyRes?.data?.smartLockStatus}
                        </span>
                      </h6>
                    </div>
                    <div className="">
                      {getOneKeyRes?.data && (
                        <SmartButtonGroup
                          handleSmartLockButtonClick={
                            handleSmartLockButtonClick
                          }
                          newKey={getOneKeyRes?.data}
                          smartLockStatus={smartLockStatus}
                        />
                      )}
                    </div>
                  </div>
                )}
                {getOneKeyRes.data?.openerStatus && (
                  <div className="col-md-6 mt-sm-3">
                    <div className="d-flex gap-3 align-items-center">
                      <h6 className="font-weight-bold text-lg">Opener </h6>
                      <h6 className="text-lg text-muted">
                        status:{" "}
                        <span className="orange-icon">
                          {getOneKeyRes?.data?.openerStatus}
                        </span>
                      </h6>
                    </div>
                    <div className="">
                      {getOneKeyRes?.data && (
                        <SmartButtonGroup
                          handleSmartLockButtonClick={handleOpenerButtonClick}
                          newKey={getOneKeyRes?.data}
                          smartLockStatus={openerStatus}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">Basic Details</h5>
          </CardHeader>
          {loading ? (
            <div className="d-flex justify-content-center my-3">
              <Spinner
                animation="border"
                role="status"
                className="text-success"
                style={{ width: "2rem", height: "2rem" }}
              />
            </div>
          ) : (
            <CardBody>
              <div className="table-responsive table-card">
                <Table className="table-borderless align-middle mb-0">
                  <tbody>
                    <tr>
                      <td className="fw-medium">Address</td>
                      <td>{getOneKeyRes?.data?.key?.address}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Flat Door Passcode</td>
                      <td>{getOneKeyRes?.data?.key?.keyPasscodeFlatDoor}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">House Door Passcode</td>
                      <td>{getOneKeyRes?.data?.key?.keyPasscodeHouseDoor}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Email:</td>
                      <td>{getOneKeyRes?.data?.key?.email}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Phone Number:</td>
                      <td>{getOneKeyRes?.data?.key?.phoneNumber}</td>
                    </tr>
                    {getOneKeyRes?.data?.key?.short_term_weekdays?.length !==
                      0 && (
                        <tr>
                          <td className="fw-medium">Short Term Week Days:</td>
                          <td>
                            {getOneKeyRes?.data?.key?.short_term_weekdays?.join(
                              ", "
                            )}
                          </td>
                        </tr>
                      )}
                    {longTermOffDays?.length !== 0 && (
                      <tr>
                        <td className="fw-medium">Long Term Off Days:</td>
                        <td>
                          {longTermOffDays
                            ?.map((date) => FormatedDate(date))
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                    {getOneKeyRes?.data?.key?.long_term_bookingTimes && (
                      <tr>
                        <td className="fw-medium">Long Term Time:</td>
                        <td>
                          {
                            getOneKeyRes?.data?.key?.long_term_bookingTimes[0]
                              ?.startTime
                          }{" "}
                          -
                          {
                            getOneKeyRes?.data?.key?.long_term_bookingTimes[0]
                              ?.endTime
                          }
                        </td>
                      </tr>
                    )}
                    {getOneKeyRes?.data?.key?.long_term_weekdays?.length !==
                      0 && (
                        <tr>
                          <td className="fw-medium">Long Term Week Days:</td>
                          <td>
                            {getOneKeyRes?.data?.key?.long_term_weekdays?.join(
                              ", "
                            )}
                          </td>
                        </tr>
                      )}
                    {getOneKeyRes?.data?.key?.propertyLink && (
                      <tr>
                        <td className="fw-medium">Property Link:</td>
                        <td>{getOneKeyRes?.data?.key?.propertyLink}</td>
                      </tr>
                    )}
                    {getOneKeyRes?.data?.key?.remarks && (
                      <tr>
                        <td className="fw-medium">Remarks:</td>
                        <td>{getOneKeyRes?.data?.key?.remarks}</td>
                      </tr>
                    )}
                    {getOneKeyRes?.data?.key?.smartlockId && (
                      <tr>
                        <td className="fw-medium">Smart Lock Id:</td>
                        <td>{getOneKeyRes?.data?.key?.smartlockId}</td>
                      </tr>
                    )}
                    {getOneKeyRes?.data?.key?.short_term_SlotLength && (
                      <tr>
                        <td className="fw-medium">Short Term Slot:</td>
                        <td>
                          {getOneKeyRes?.data?.key?.short_term_SlotLength}
                        </td>
                      </tr>
                    )}
                    {getOneKeyRes?.data?.key?.short_term_bookingTimes && (
                      <tr>
                        <td className="fw-medium">Short Term Time:</td>
                        <td>
                          {getOneKeyRes?.data?.key?.short_term_bookingTimes
                            .map(
                              (time) => `${time.startTime} - ${time.endTime}`
                            )
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          )}
        </Card>

        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Key Notes</h5>
              <Button
                color="secondary"
                variant="outline"
                className="edit-icon-button px-3 py-1 mb-1 blue-button"
                onClick={() => setNote(true)}
              >
                Add
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {getOneKeyRes?.data &&
              getOneKeyRes?.data?.notes.map((note, index) => (
                <div className="d-flex align-items-center gap-2 mb-2">
                  <span className="fw-bold">{index + 1}</span>
                  <Input
                    type="text"
                    value={note?.content}
                    rows={2}
                    readOnly
                    className="form-control"
                    style={{
                      flex: 1,
                      height: "auto",
                      maxHeight: "80px",
                    }}
                  />
                  <span>
                    <RiEyeLine
                      size={18}
                      onClick={() => SeeNote(note)}
                      className="me-1 ho cursor-pointer"
                    />
                  </span>
                  <span>
                    <LuClipboardEdit
                      size={18}
                      onClick={() => EditNote(note)}
                      className="me-1 ho cursor-pointer"
                    />
                  </span>
                  <span>
                    <RiDeleteBin6Line
                      onClick={() => deleteNote(note)}
                      size={18}
                      className="me-1 cursor-pointer"
                    />
                  </span>
                </div>
              ))}
            {note && (
              <NoteModal
                note={note}
                setNote={setNote}
                ID={ID}
                label={"Add Note"}
              />
            )}
            {editNote && (
              <NoteModal
                note={editNote}
                setNote={setEditNote}
                label={"Edit Note"}
                noteData={noteData}
              />
            )}
            {seeNote && (
              <NoteModal
                note={seeNote}
                setNote={setSeeNote}
                label={"See Note"}
                noteData={noteData}
              />
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">Key Images</h5>
          </CardHeader>
          <CardBody>
            <FormGroup style={{ backgroundColor: "white", marginBottom: 3 }}>
              {getOneKeyRes?.data?.key?.mediaIds?.map((img) => (
                <figure className="figure mb-0">
                  <img
                    key={img?.url}
                    src={img?.url}
                    className="figure-img img-fluid rounded"
                    style={{
                      maxWidth: "200px",
                      width: "100%",
                      marginRight: "5px",
                    }}
                    alt="..."
                  />
                </figure>
              ))}
            </FormGroup>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ViewKey;
