import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import NoteModal from "../components/NoteModal";
import DeleteModal from "../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteKeyNote, getNotes } from "../../../slices/swipeNotes/thunk";
import { FormatedDate } from "../../../helpers/date_helper";
import {
  resetdeleteKeyNote,
  resetEditNote,
} from "../../../slices/swipeNotes/reducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Notes = () => {
  const [editNote, setEditNote] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [noteData, setNoteData] = useState({});
  const [noteDeleteId, setNoteDeleteId] = useState(null);

  const dispatch = useDispatch();
  const getAllNotesRes = useSelector((state) => state.SwipeNotesSlice.getNotes);
  const editNoteRes = useSelector((state) => state.SwipeNotesSlice.editNote);
  const deleteNoteRes = useSelector(
    (state) => state.SwipeNotesSlice.deleteKeyNote
  );

  const getAllNotes = () => {
    dispatch(getNotes());
  };

  const deleteNote = (note) => {
    setDeleteModal(true);
    setNoteDeleteId(note._id);
  };

  useEffect(() => {
    if (editNoteRes.success) {
      getAllNotes();
      toast(editNoteRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      dispatch(resetEditNote());
    } else if (deleteNoteRes.success) {
      getAllNotes();
      toast(deleteNoteRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      dispatch(resetdeleteKeyNote());
    } else {
      getAllNotes();
    }
  }, [editNoteRes.success, deleteNoteRes.success]);

  const handleDeleteKey = () => {
    if (noteDeleteId !== null) {
      dispatch(deleteKeyNote(noteDeleteId));
      setDeleteModal(false);
    }
  };

  function EditNote(note) {
    setEditNote(!editNote);
    setNoteData(note);
  }

  return (
    <>
      <ToastContainer autoClose={2000} limit={1} />

      {editNote && (
        <NoteModal
          note={editNote}
          setNote={setEditNote}
          label={"Edit Note"}
          noteData={noteData}
        />
      )}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteKey}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {getAllNotesRes.data && getAllNotesRes.data.length > 0 ? (
            getAllNotesRes.data.map((note) => (
              <Row key={note._id}>
                <div className="card task-box" id="uptask-1">
                  <CardBody>
                    <UncontrolledDropdown className="float-end cursor-pointer">
                      <DropdownToggle
                        className="arrow-none"
                        tag="a"
                        color="white"
                      >
                        <i className="ri-more-fill"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                          className="edittask-details"
                          onClick={() => EditNote(note)}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          className="deletetask"
                          onClick={() => deleteNote(note)}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="mb-3">
                      <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title">
                        {note?.userId?.firstName} {note?.userId?.lastName}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <h6>Note : </h6>
                        <p className="text-muted ms-2">{note.content}</p>
                      </div>
                      <div>
                        {note?.mediaIds?.map((img) => (
                          <figure className="figure mb-0" key={img._id}>
                            <img
                              src={img?.url}
                              className="figure-img img-fluid rounded"
                              style={{
                                maxWidth: "70px",
                                width: "100%",
                                marginRight: "5px",
                              }}
                              alt="..."
                            />
                          </figure>
                        ))}
                      </div>
                    </div>
                  </CardBody>
                  <div className="card-footer border-top-dashed">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <span className="text-muted">
                          {FormatedDate(note.createdAt)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            ))
          ) : (
            <div className="d-flex justify-content-center">
            <div>
              <h4 className="text-white py-2 px-4 bg-danger rounded shadow">
                No Notes found
              </h4>
            </div>
          </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Notes;
