import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllKeysData,
} from "../../helpers/fakebackend_helper";

export const getAllKeys = createAsyncThunk(
  "requests/getAllKeysData",
  async (params) => {
    try {
      const response = getAllKeysData(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);
