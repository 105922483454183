import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Table,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  FormatTime,
  FormatedDate,
  FormatedTime,
} from "../../../helpers/date_helper";
import { Booking, getOneRequest } from "../../../slices/bookedKeys/thunk";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  resetBooking,
  resetBookingError,
} from "../../../slices/bookedKeys/reducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../components/DeleteModal";

const ViewRequest = () => {
  const dispatch = useDispatch();

  const { ID } = useParams();

  const getOneRequestRes = useSelector(
    (state) => state.BookedSlice.getOneRequest
  );
  const loading = useSelector((state) => state.BookedSlice.loading);
  const bookingRes = useSelector((state) => state.BookedSlice.booking);
  console.log('bookingRes', bookingRes);
  const [rejectedId, setRejectedId] = useState(null);
  const [rejectedStatus, setRejectedStatus] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);


  const getOneRequestFunc = () => {
    dispatch(getOneRequest(ID));
  };

  useEffect(() => {
    if (bookingRes && bookingRes.success) {
      getOneRequestFunc();
      dispatch(resetBooking());
      dispatch(resetBookingError());
    }
  }, [bookingRes, dispatch]);

  const handleBooking = (id, status) => {
    let params = {
      bookingId: id,
      status: status,
    };
    if (status === "rejected") {
      setRejectedId(id);
      setRejectedStatus(status);
      setDeleteModal(true);
    } else {
      dispatch(Booking(params));
    }
  };

  const confirmDelete = () => {
    if (rejectedId !== null) {
      let params = {
        bookingId: rejectedId,
        status: rejectedStatus,
      };
      dispatch(Booking(params));
      setDeleteModal(false);
      setRejectedId(null);
      setRejectedStatus(null);
    }
  };

  useEffect(() => {
    getOneRequestFunc();
  }, []);

  return (
    <div className="page-content">
      <Card></Card>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={confirmDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h5 className="card-title mb-0">Basic Details</h5>
            <span
              className={`badge text-uppercase ${getOneRequestRes?.data?.booking?.status === "cancelled"
                ? "bg-danger"
                : getOneRequestRes?.data?.booking?.status ===
                  "pending_approval"
                  ? "bg-warning"
                  : getOneRequestRes?.data?.booking?.status === "rejected"
                    ? "bg-danger"
                    : "bg-success"
                }`}
            >
              {getOneRequestRes?.data?.booking?.status}
            </span>
          </div>
        </CardHeader>
        {loading ? (
          <div className="d-flex justify-content-center my-3">
            <Spinner
              animation="border"
              role="status"
              className="text-success"
              style={{ width: "2rem", height: "2rem" }}
            />
          </div>
        ) : (
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  {getOneRequestRes?.data?.booking?.type &&
                    getOneRequestRes?.data?.booking?.type === "short-term" && (
                      <tr>
                        <td className="fw-medium">Booking Time</td>
                        <td>
                          {FormatedTime(
                            getOneRequestRes?.data?.booking?.startTime
                          )}{" "}
                          -{" "}
                          {FormatedTime(
                            getOneRequestRes?.data?.booking?.endTime
                          )}
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td className="fw-medium">Key Name</td>
                    <td>{getOneRequestRes?.data?.booking?.keyId?.name}</td>
                  </tr>
                  {getOneRequestRes?.data?.booking?.keyId
                    ?.long_term_offDays && (
                      <tr>
                        <td className="fw-medium">Long Term Off Days</td>
                        <td>
                          {getOneRequestRes?.data?.booking?.keyId?.long_term_offDays
                            .map((date) => FormatedDate(date))
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                  {getOneRequestRes?.data?.booking?.keyId
                    ?.long_term_weekdays && (
                      <tr>
                        <td className="fw-medium">Long Term Week Days</td>
                        <td>
                          {getOneRequestRes?.data?.booking?.keyId?.long_term_weekdays
                            .map((day) => day)
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                  {getOneRequestRes?.data?.booking?.keyId
                    ?.short_term_offDays && (
                      <tr>
                        <td className="fw-medium">Short Term Off Days</td>
                        <td>
                          {getOneRequestRes?.data?.booking?.keyId?.short_term_offDays
                            .map((date) => FormatedDate(date))
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                  {getOneRequestRes?.data?.booking?.keyId
                    ?.short_term_offDays && (
                      <tr>
                        <td className="fw-medium">Short Term Off Days</td>
                        <td>
                          {getOneRequestRes?.data?.booking?.keyId?.short_term_offDays
                            .map((date) => FormatedDate(date))
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                  {getOneRequestRes?.data?.booking?.startTime && (
                    <tr>
                      <td className="fw-medium">Booking Start Date</td>
                      <td>
                        {FormatedDate(
                          getOneRequestRes?.data?.booking?.startTime
                        )}
                      </td>
                    </tr>
                  )}
                  {getOneRequestRes?.data?.booking?.endTime && (
                    <tr>
                      <td className="fw-medium">Booking End Date</td>
                      <td>
                        {FormatedDate(getOneRequestRes?.data?.booking?.endTime)}
                      </td>
                    </tr>
                  )}
                  {getOneRequestRes?.data?.booking?.type && (
                    <tr>
                      <td className="fw-medium">Booking type</td>
                      <td>{getOneRequestRes?.data?.booking?.type}</td>
                    </tr>
                  )}
                  {getOneRequestRes?.data?.booking?.keyId
                    ?.short_term_weekdays && (
                      <tr>
                        <td className="fw-medium">Short Term Week Days</td>
                        <td>
                          {getOneRequestRes?.data?.booking?.keyId?.short_term_weekdays
                            .map((day) => day)
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        )}
      </Card>
      {getOneRequestRes?.data?.booking?.status === "pending_approval" && (
        <div className="d-flex justify-content-end gap-2 mt-3 mb-3 me-2">
          <Button
            style={{
              background: "#15294E",
              color: "white",
              borderColor: "gray",
            }}
            onClick={() =>
              handleBooking(getOneRequestRes?.data?.booking._id, "booked")
            }
            className="blue-button"
          >
            Accept Booking
          </Button>
          <Button
            onClick={() =>
              handleBooking(getOneRequestRes?.data?.booking._id, "rejected")
            }
            color="danger"
            variant="outline"
            className="d-flex align-items-center justify-content-center hover-red"
          >
            <RiDeleteBin6Line className="me-1" />
            Reject Booking
          </Button>
        </div>
      )}
      {getOneRequestRes?.data?.booking?.status === "booked" && (
        <div className="d-flex justify-content-end gap-2 mt-3 me-2 mb-2">
          <Button
            onClick={() =>
              handleBooking(getOneRequestRes?.data?.booking._id, "cancelled")
            }
            color="danger"
            variant="outline"
            className="d-flex align-items-center justify-content-center hover-red"
          >
            <RiDeleteBin6Line className="me-1" />
            Cancel
          </Button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ViewRequest;
