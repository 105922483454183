import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AddNoteData,
  EditNoteData,
  getNotesData,
  deleteNoteData,
} from "../../helpers/fakebackend_helper";

export const AddNote = createAsyncThunk(
  "swipeNotes/AddNote",
  async (params) => {
    try {
      const response = AddNoteData(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getNotes = createAsyncThunk(
  "swipeNotes/getNotesData",
  async (params) => {
    try {
      const response = getNotesData(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteKeyNote = createAsyncThunk(
  "swipeNotes/deleteKeyNote",
  async (params) => {
    try {
      const response = deleteNoteData(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const EditNote = createAsyncThunk(
  "swipeNotes/EditNote",
  async ({ values,mediaIds, ID }) => {
    let params = {
      content: values,
      mediaIds: mediaIds,
    };

    try {
      const response = EditNoteData(params, ID);
      return response;
    } catch (error) {
      return error;
    }
  }
);
