import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../global.css";
import { LocationIcon } from "../components/svg/Svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllKeyGroups } from "../../../slices/keyGroups/thunk";
import {
  resetAddKeyGroup,
  resetDeleteKeyGroup,
  resetError,
} from "../../../slices/keyGroups/reducer";
import TableComponent from "../components/TableComponent";
import Pagination from "../../../Components/Common/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WarningAlert from "../components/WarningAlert";
import TableContainer from "../../../Components/Common/TableContainer";
import { debounce } from "lodash";

const KeyGroups = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const perPageData = 10;

  // const pageRes = useSelector((state) => state.KeySlice.page);

  const keyGroupsRes = useSelector(
    (state) => state.KeyGroupsSlice.allKeyGroups
  );

  const AddKeyGroupRes = useSelector(
    (state) => state.KeyGroupsSlice.addKeyGroup
  );

  const deleteKeyGroupRes = useSelector(
    (state) => state.KeyGroupsSlice.deleteKeyGroupState
  );

  const loading = useSelector((state) => state.KeyGroupsSlice.loading);
  const errorRes = useSelector((state) => state.KeyGroupsSlice.error);

  const handleNextKeyGroupApi = () => {
    setPage((val) => val + 1);
    let params = {
      page: page + 1,
      limit: perPageData,
    };
    if (searchQuery) {
      params.search = searchQuery;
    }
    dispatch(getAllKeyGroups(params));
  };

  const handlePrevKeyGroupApi = () => {
    setPage((val) => val - 1);
    let params = {
      page: page - 1,
      limit: perPageData,
    };
    if (searchQuery) {
      params.search = searchQuery;
    }
    dispatch(getAllKeyGroups(params));
  };

  const handleSearchChange = debounce((text) => {
    setPage(1)
    setSearchQuery(text);
  }, 500);

  useEffect(() => {
    let params = {};

    if (searchQuery) {
      params.search = searchQuery;
    } else {
      params.page = currentPage;
      params.limit = perPageData;
    }

    dispatch(getAllKeyGroups(params));
    dispatch(resetDeleteKeyGroup());
  }, [deleteKeyGroupRes.success, dispatch, searchQuery]);

  useEffect(() => {
    if (AddKeyGroupRes && AddKeyGroupRes?.success) {
      toast(AddKeyGroupRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      dispatch(resetAddKeyGroup());
    }
    if (errorRes) {
      toast.error(errorRes?.message);
    }
  }, [AddKeyGroupRes?.success]);

  useEffect(() => {
    if (deleteKeyGroupRes && deleteKeyGroupRes.success) {
      toast(deleteKeyGroupRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    }
  }, [deleteKeyGroupRes.success]);

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        header: "Key Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          const name = getValue();
          const keyId = row.original._id;

          return (
            <div>
              <Link to={`/view-key/${keyId}`} key={keyId}>
                {name}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          const email = getValue();
          const keyId = row.original._id;

          return (
            <div>
              <Link to={`/view-key/${keyId}`} key={keyId}>
                {email}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Phone Number",
        accessorKey: "phoneNumber",
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          const phoneNumber = getValue();
          const keyId = row.original._id;

          return (
            <div>
              <Link to={`/view-key/${keyId}`} key={keyId}>
                {phoneNumber}
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  // Pagination
  const paginatedData = keyGroupsRes?.data?.slice(
    (currentPage - 1) * perPageData,
    currentPage * perPageData
  );

  return (
    <>
      <div className="page-content">
        {errorRes && <WarningAlert msg={errorRes} />}
        <ToastContainer autoClose={2000} limit={1} />

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <h6 className="card-title mb-0 flex-grow-1 fw-bold">
                    Key Chains
                  </h6>
                  <div className="flex-shrink-0">
                    <Link to="/add-keygroup">
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#CreateJobModal"
                      >
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add Keychain
                      </button>
                    </Link>
                  </div>
                </div>

                <Row className="mt-3 gy-3">
                  <Col xxl={12} md={6}>
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search bg-light border-light"
                        id="searchJob"
                        autoComplete="off"
                        placeholder="Search for Keychain..."
                        onChange={(e) => handleSearchChange(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  {/* <Col xxl={2} ms={6}>
                    <div className="input-light">
                      <select
                        className="form-control"
                        data-choices
                        data-choices-search-false
                        name="choices-single-default"
                        id="idStatus"
                        defaultValue="Newest"
                      >
                        <option value="All">All Selected</option>
                        <option value="Newest" defaultValue>
                          Newest
                        </option>
                        <option value="Popular">Popular</option>
                        <option value="Oldest">Oldest</option>
                      </select>
                    </div>
                  </Col> */}
                  <Col className="col-xl-12 d-none" id="found-job-alert">
                    <div
                      className="alert alert-success mb-0 text-center"
                      role="alert"
                    >
                      <strong id="total-result">253</strong> jobs found
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {loading ? (
          <div className="d-flex justify-content-center my-3">
            <Spinner
              animation="border"
              role="status"
              className="text-success"
              style={{ width: "2rem", height: "2rem" }}
            />
          </div>
        ) : (
          <div>
            <div>
              {paginatedData && paginatedData.length > 0 ? (
                paginatedData.map((keyGroup) => (
                  <div key={keyGroup._id}>
                    <Row>
                      <Col xxl={12} sm={12} className="project-card">
                        <Col className="card-height-100">
                          <Card className="card-height-100">
                            <CardBody>
                              <div className="mb-3">
                                <div className=" px-3 rounded-3 h-100">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="fw-semibold">
                                      {keyGroup.name}
                                    </h5>
                                    <div>
                                      <Link to={`/view-keygroup/${keyGroup._id}`}>
                                        <Button
                                          variant="outline"
                                          className="d-flex align-items-center justify-content-center white-button w-100 w-sm-50"
                                        >
                                          <span>View Keychain</span>
                                        </Button>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center mt-2">
                                    <div className="d-flex">
                                      <LocationIcon width={20} height={20} />
                                    </div>
                                    <div className="d-flex gap-2 text-center">
                                      <h6 className="text-muted mt-1">
                                        Address:{" "}
                                      </h6>
                                      <h6 className="mt-1">{keyGroup.address}</h6>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <Card>
                                      <CardHeader>
                                        <h4 className="card-title mb-0">
                                          List of all Keys:
                                        </h4>
                                      </CardHeader>
                                      <CardBody>
                                        <TableComponent
                                          data={keyGroup?.keysList || []}
                                          columns={columns}
                                          total={keyGroup?.keysList?.length}
                                          customPageSize={5}
                                          isGlobalFilter={true}
                                          divClass="table-responsive mb-1"
                                          tableClass="mb-0 align-middle table-borderless"
                                          theadClass="table-light text-muted"
                                        />
                                      </CardBody>
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center">
                  <div>
                    <h4 className="text-white p-2 bg-danger rounded shadow">
                      No KeyChains found
                    </h4>
                  </div>
                </div>
              )}
            </div>
            <Pagination
              data={keyGroupsRes?.data}
              currentPage={page}
              setCurrentPage={setCurrentPage}
              perPageData={perPageData}
              handleNextKeysApi={handleNextKeyGroupApi}
              handlePrevKeysApi={handlePrevKeyGroupApi}
              customPageSize={10}
              total={10}

            />
          </div>
        )}
      </div>

    </>
  );
};

export default KeyGroups;
