import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Select, { components } from "react-select";
import { flattenProduct } from "../../../helpers/date_helper";
import Pagination from "../../../Components/Common/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getAllKeys } from "../../../slices/keys/thunk";

const MultiSelector = (props) => {
  const [disselectMulti, setdisselectMulti] = useState(null);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const allKeysRes = useSelector((state) => state.KeySlice.allKeys);

  const perPageData = 10;

  const dispatch = useDispatch();

  const fetchKeys = (page, searchQuery) => {
    const params = {
      page,
      limit: perPageData,
      keyword: searchQuery,
    };
    dispatch(getAllKeys(params));
  };

  useEffect(() => {
    fetchKeys(page, searchQuery);
  }, [page, searchQuery, dispatch]);

  const handleNextKeysApi = () => {
    setPage((val) => val + 1);
  };

  const handlePrevKeysApi = () => {
    setPage((val) => val - 1);
  };

  useEffect(() => {
    if (props && props.selectedData && props.selectedData.length > 0) {
      const data = flattenProduct(props.selectedData);
      setdisselectMulti(data);
    }
  }, [props.selectedData]);

  const handleInputChange = (newValue) => {
    const inputValue = newValue.trim();
    setSearchQuery(inputValue);
    setPage(1); // Reset to the first page whenever a new search term is entered
    return inputValue;
  };

  const customStyles = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#3762ea",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      backgroundColor: "#405189",
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "white",
      backgroundColor: "#405189",
      ":hover": {
        backgroundColor: "#405189",
        color: "white",
      },
    }),
  };

  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <div className="pagination-wrapper mt-3 me-3">
          <Pagination
            data={flattenProduct(allKeysRes?.data)}
            currentPage={page}
            setCurrentPage={setCurrentPage}
            perPageData={perPageData}
            handleNextKeysApi={handleNextKeysApi}
            handlePrevKeysApi={handlePrevKeysApi}
            customPageSize={10}
            total={10}
          />
        </div>
      </components.MenuList>
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="">
            <Select
              value={disselectMulti}
              placeholder={`${props.placeholder}`}
              isMulti={true}
              onChange={(selectedItems) => {
                props.handleSelectedFnc(selectedItems);
                setdisselectMulti(selectedItems);
              }}
              options={flattenProduct(allKeysRes?.data)}
              classNamePrefix="js-example-disabled-multi mb-0"
              styles={customStyles}
              components={{ MenuList: CustomMenuList }}
              onInputChange={handleInputChange}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MultiSelector;
