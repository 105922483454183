import React from "react";
import { Navigate } from "react-router-dom";

//Swipe Routes
import DashboardEcommerce from "../pages/DashboardEcommerce";
//keygroup
import KeyGroups from "../pages/SwipePages/KeyGroups";
import AddKeyGroups from "../pages/SwipePages/KeyGroups/AddKeyGroups";
import ViewKeyGroups from "../pages/SwipePages/KeyGroups/ViewKeyGroup";
 
//Key
import Keys from "../pages/SwipePages/Key";
import AddKeys from "../pages/SwipePages/Key/AddKeys";
import ViewKey from "../pages/SwipePages/Key/ViewKey";

//Requests 
import Requests from "../pages/SwipePages/Requests";
import ViewRequests from "../pages/SwipePages/Requests/ViewRequest";

//Booked Keys
import BookedKeys from "../pages/SwipePages/BookedKeys";
import ViewBookedKeys from "../pages/SwipePages/BookedKeys/ViewBookedKey";

//Notes
import Notes from "../pages/SwipePages/Notes";

//abot
import About from "../pages/SwipePages/About/About";
import Privacy from "../pages/SwipePages/About/Privacy";
import Tc from "../pages/SwipePages/About/Tc";

//connected
import ConnectedSmartLock from "../pages/SwipePages/connectedSmartlock";


//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
//pages
import Maintenance from "../pages/Pages/Maintenance/Maintenance";
import ComingSoon from "../pages/Pages/ComingSoon/ComingSoon";

import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import Otp from "../pages/Authentication/Otp";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import LongTermBooking from "../pages/Authentication/LongBooking";
import ShortTermBooking from "../pages/Authentication/ShortBooking";
import CongratulationPage from "../pages/Authentication/Congratulation";

//Charts

import PrivecyPolicy from "../pages/Pages/PrivacyPolicy";
import TermsCondition from "../pages/Pages/TermsCondition";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import ViewKeyGroup from "../pages/SwipePages/KeyGroups/ViewKeyGroup";
import Congratulation from "../pages/Authentication/Congratulation";
import WelcomeAdmin from "../pages/Authentication/WelcomeAdmin";
import EditKeyGroups from "../pages/SwipePages/KeyGroups/EditKeyGroups";
import EditKey from "../pages/SwipePages/Key/EditKey";

const authProtectedRoutes = [
  //Seipe routes
  { path: "/dashboard", component: <DashboardEcommerce /> },
  //keygroups routes
  { path: "/all-keygroups", component: <KeyGroups /> },
  { path: "/view-keygroup/:ID", component: <ViewKeyGroup /> },
  // { path: "/keygroups/:ID", component: <KeyGroups /> },
  { path: "/add-keygroup", component: <AddKeyGroups /> },
  { path: "/edit-keygroup/:ID", component: <EditKeyGroups /> },

  //Key
  { path: "/all-keys", component: <Keys /> },
  { path: "/add-keys", component: <AddKeys /> },
  { path: "/edit-key/:ID", component: <EditKey /> },
  { path: "/view-key/:ID", component: <ViewKey /> },

  //requests
  { path: "/requests", component: <Requests /> },
  { path: "/requests/:ID", component: <ViewRequests /> },

  //Booked keys
  { path: "/bookedKeys", component: <BookedKeys /> },
  { path: "/bookedKeys/:ID", component: <ViewBookedKeys /> },

  //Bookings

  //Notes
  { path: "/notes", component: <Notes /> },

  //about
  { path: "/about", component: <About /> },
  { path: "/about-privacy", component: <Privacy /> },
  { path: "/about-tc", component: <Tc /> },

  //smartlock
  { path: "/connectedSmartLock", component: <ConnectedSmartLock /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  //Swipe Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/otp", component: <Otp /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/book-a-key/long-term/:ID", component: <LongTermBooking /> },
  { path: "/book-a-key/short-term/:ID", component: <ShortTermBooking /> },
  { path: "/book-a-key/congrats", component: <CongratulationPage /> },
  { path: "/welcome-admin/:token", component: <WelcomeAdmin /> },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
