import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
// import { cancelOrder, getAllOrders, readyToShip } from "../../../slices/thunks";
// import { resetOrdersState } from "../../../slices/orders/reducer";
import { Booking, getAllRequests } from "../../../slices/bookedKeys/thunk";
import { FormatedDate, FormatedTime } from "../../../helpers/date_helper";
import WarningAlert from "../components/WarningAlert";
import DeleteModal from "../components/DeleteModal";
import {
  resetBooking,
  resetBookingError,
  resetReqStatus,
  resetRequestState,
  setPageRequest,
  setReqStatus,
} from "../../../slices/bookedKeys/reducer";

const Requests = ({ header = true }) => {
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [allRequests, setAllRequests] = useState([]);
  const [rejectedId, setRejectedId] = useState(null);
  const [rejectedStatus, setRejectedStatus] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const pageRes = useSelector((state) => state.BookedSlice.pageReq);
  const requestStatusRes = useSelector((state) => state.BookedSlice.reqStatus);
  const startDateRes = useSelector((state) => state.BookedSlice.startRequestTime);
  const endDateRes = useSelector((state) => state.BookedSlice.endRequestTime);
  const requestKeywordRes = useSelector((state) => state.BookedSlice.requestKeyword);

  // const loading = useSelector((state) => state.BookedSlice.loading);
  const errorRes = useSelector((state) => state.BookedSlice.error);
  const bookingRes = useSelector((state) => state.BookedSlice.booking.success);
  const getAllRequestRes = useSelector(
    (state) => state.BookedSlice.allRequests

  );

  useEffect(() => {
    return () => dispatch(resetReqStatus());
  }, []);

  // useEffect(() => {
  //   let params = {};
  //   params.page = pageRes;
  //   params.limit = 10;

  //   fetchData(params);

  // }, [bookingRes])

  useEffect(() => {
    let params = {};
    params.page = pageRes;
    params.limit = 10;
    params.status = requestStatusRes;
    params.startTime = startDateRes;
    params.endTime = endDateRes;
    params.keyName = requestKeywordRes;

    dispatch(getAllRequests(params));
    dispatch(resetBooking());
    dispatch(resetBookingError());
  }, [
    pageRes,
    requestStatusRes,
    bookingRes,
    requestKeywordRes,
    endDateRes,
  ]);

  useEffect(() => {
    if (getAllRequestRes && getAllRequestRes?.success) {
      setAllRequests(getAllRequestRes.data);
    }
  }, [getAllRequestRes]);

  const handleBooking = (id, status) => {
    let params = {
      bookingId: id,
      status: status,
    };
    if (status === "rejected") {
      setRejectedId(id);
      setRejectedStatus(status);
      setDeleteModal(true);
    } else {
      dispatch(Booking(params));
    }
  };

  const confirmDelete = () => {
    if (rejectedId !== null) {
      let params = {
        bookingId: rejectedId,
        status: rejectedStatus,
      };
      dispatch(Booking(params));
      setDeleteModal(false);
      setRejectedId(null);
      setRejectedStatus(null);
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (type !== "all") {
        dispatch(setPageRequest(1));
        dispatch(setReqStatus(type));
      } else {
        dispatch(setPageRequest(1));
        dispatch(setReqStatus(""));
      }
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const handleOrderClicks = () => {
    setOrder("");
    toggle();
  };

  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      toggle();
    },
    [toggle]
  );
  // Column
  const columns = useMemo(
    () => [
      {
        header: "Key Name",
        accessorKey: "key",
        cell: (cell) => {
          return (
            <Link
              to={`/requests/${cell.row.original._id}`}
              className="text-primary d-inline-block"
            >
              {cell.getValue()?.name}
            </Link>
          );
        },
      },
      {
        header: "Start Time",
        accessorKey: "startTime",
        cell: (cell) => (
          <>
            {FormatedDate(cell.getValue())} {"<>"}
            {FormatedTime(cell.getValue())}
          </>
        ),
      },
      {
        header: "End Time",
        accessorKey: "endTime",
        cell: (cell) => (
          <>
            {FormatedDate(cell.getValue())} {"<>"}
            {FormatedTime(cell.getValue())}
          </>
        ),
      },
      {
        header: "Type",
        accessorKey: "type",
        cell: (cell) => <>{cell.getValue()}</>,
      },
      {
        header: "User Name",
        accessorKey: "user",
        cell: (cell) => (
          <>{cell.getValue().firstName + " " + cell.getValue()?.lastName}</>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: (cell) => {
          const status = cell.getValue();
          const getStatusStyle = (status) => {
            switch (status) {
              case "cancelled":
                return "bg-danger text-white";
              case "rejected":
                return "bg-danger text-white";
              case "booked":
                return "bg-success text-white";
              case "pending_approval":
                return "bg-warning text-dark";
              case "booked":
                return "bg-warning text-dark";
              default:
                return "";
            }
          };
          return (
            <div className={`p-2 badge ${getStatusStyle(status)}`}>
              {status}
            </div>
          );
        },
      },
      {
        header: "Action",
        cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <UncontrolledDropdown>
                <DropdownToggle
                  href="#"
                  className="btn btn-soft-secondary btn-sm"
                  tag="button"
                >
                  <i className="ri-more-fill" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    tag={Link}
                    to={`/requests/${cellProps.row.original._id}`}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    tag={Link}
                    onClick={() =>
                      handleBooking(cellProps.row.original._id, "booked")
                    }
                  >
                    Approve
                  </DropdownItem>
                  {cellProps.row.original.status !== "cancelled" && (
                    <DropdownItem
                      tag={Link}
                      onClick={() =>
                        handleBooking(cellProps.row.original._id, "cancelled")
                      }
                    >
                      Cancel
                    </DropdownItem>
                  )}
                  <DropdownItem
                    tag={Link}
                    onClick={() =>
                      handleBooking(cellProps.row.original._id, "rejected")
                    }
                  >
                    Reject
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          );
        },
      },
    ],
    [handleOrderClick]
  );

  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };

  document.title = "Swipe Panel";

  return (
    <div className={header ? "page-content" : ""}>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={confirmDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ToastContainer limit={1} />
      <Container fluid>
        {header && <BreadCrumb title="Requests" pageTitle="" />}
        {errorRes && errorRes ? <WarningAlert msg={errorRes} /> : null}
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>
                        All Requests
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2", "rejected");
                        }}
                        href="#"
                      >
                        <i className="ri-thumb-down-line me-1 align-bottom"></i>{" "}
                        Rejected
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3", "cancelled");
                        }}
                        href="#"
                      >
                        <i className="ri-close-circle-line me-1 align-bottom"></i>{" "}
                        Cancelled
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4", "pending_approval");
                        }}
                        href="#"
                      >
                        <i className="ri-time-line me-1 align-bottom"></i>{" "}
                        Pending
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "5" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("5", "booked");
                        }}
                        href="#"
                      >
                        <i className="ri-bookmark-line me-1 align-bottom"></i>{" "}
                        Booked
                      </NavLink>
                    </NavItem>
                  </Nav>

                  {/* {loading ? (
                    <div className="d-flex justify-content-center my-3">
                      <Spinner
                        animation="border"
                        role="status"
                        className="text-success"
                        style={{ width: "2rem", height: "2rem" }}
                      />
                    </div>
                  ) : ( */}
                  <TableContainer
                    columns={columns}
                    data={allRequests || []}
                    total={getAllRequestRes.totalBookings}
                    isGlobalFilter={true}
                    isRequestFilter={true}
                    isAddUserList={false}
                    customPageSize={10}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                    handleOrderClick={handleOrderClicks}
                    SearchPlaceholder="Search Requests..."
                    pageRes={pageRes}
                  />
                  {/* )} */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Requests;
