import React, { useState } from "react";
import { UncontrolledAlert } from "reactstrap";

const WarningAlert = ({ msg }) => {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => {
    setVisible(false);
    msg = "";
  };
  return (
    <div>
      {visible && (
        <div className="mb-3">
          <UncontrolledAlert
            color="danger"
            className="alert-additional material-shadow mb-0"
            toggle={onDismiss}
          >
            <div className="alert-body">
              <div className="flex-grow-1">
                <h5 className="alert-heading">{msg}</h5>
              </div>
            </div>
          </UncontrolledAlert>
        </div>
      )}
    </div>
  );
};

export default WarningAlert;
