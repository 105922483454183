import { createSlice } from "@reduxjs/toolkit";


import {
  getAllKeys,
  getShortSlotsThuk,
  getLongSlotsThuk,
  createShortBooking,
  createLongBooking,
  getAllBookings,
  getAllSmartLocks,
  getLockStatus,
  lockSmartLock,
  unlockSmartLock,
  getSmartLockLogs,
  lockByBooking,
  unlockByBooking,
  getOneBooking,
  getAllRequests,
  getOneRequest,
  Booking,
} from "./thunk";
import { toast } from "react-toastify";

export const initialState = {
  allKeys: [],
  booking: {},
  shortBooking: {},
  longBooking: {},
  getShortSlots: [],
  getLongSlots: [],
  allBookings: [],
  allRequests: [],
  getOneBooking: {},
  getOneRequest: {},
  getAllSmartLocks: [],
  getLockStatus: [],
  lockSmartLock: [],
  unlockSmartLock: [],
  getSmartLockLogs: [],
  lockByBooking: [],
  unlockByBooking: [],
  loading: false,
  pageBook: 1,
  pageReq: 1,
  error: "",
  status: "",
  startRequestTime: "",
  endRequestTime: "",
  startBookingTime: "",
  endBookingTime: "",
  bookingKeyword: "",
  requestKeyword: "",
  reqStatus: "",
  startReqTime: "",
  endReqTime: "",
  btnLoading: false,
};

const BookedSlice = createSlice({
  name: "bookedKeys",
  initialState,
  reducers: {
    resetOneBooking: (state) => {
      state.getOneBooking = {};
    },
    resetBookingState: (state) => {
      state.pageBook = 1;
      state.startBookingTime = "";
      state.endBookingTime = "";
      state.bookingKeyword = "";
    },
    resetRequestState: (state) => {
      state.pageReq = 1;
      state.startRequestTime = "";
      state.endRequestTime = "";
      state.requestKeyword = "";
    },
    resetReqStatus: (state) => {
      state.reqStatus = "";
    },
    resetStatus: (state) => {
      state.status = "";
    },
    setBookingKeyword: (state, action) => {
      if (action.payload.length > 0) {
        state.pageBook = 1;
        state.bookingKeyword = action.payload;
      } else {
        state.bookingKeyword = action.payload;
      }
    },
    setRequestKeyword: (state, action) => {
      if (action.payload.length > 0) {
        state.pageReq = 1;
        state.requestKeyword = action.payload;
      } else {
        state.requestKeyword = action.payload
      }
    },
    setBookingStatus: (state, action) => {
      state.status = action.payload;
    },
    setReqStatus: (state, action) => {
      state.reqStatus = action.payload;
    },
    setStartBookingTime: (state, action) => {
      state.startBookingTime = action.payload;
    },
    setEndBookingTime: (state, action) => {
      state.endBookingTime = action.payload;
    },
    setStartReqTime: (state, action) => {
      state.startReqTime = action.payload;
    },
    setEndReqTime: (state, action) => {
      state.endReqTime = action.payload;
    },
    resetBooking: (state) => {
      state.booking = {};
    },
    setPageBooking(state, action) {
      state.pageBook = action.payload;
    },
    setPageRequest(state, action) {
      state.pageReq = action.payload;
    },
    resetBookingError(state) {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllKeys.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllKeys.fulfilled, (state, action) => {
      state.loading = false;
      state.allKeys = action.payload;
    });
    builder.addCase(getAllKeys.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(createShortBooking.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createShortBooking.fulfilled, (state, action) => {
      state.loading = false;
      state.shortBooking = action.payload;
    });
    builder.addCase(createShortBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(createLongBooking.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createLongBooking.fulfilled, (state, action) => {
      state.loading = false;
      state.longBooking = action.payload;
    });
    builder.addCase(createLongBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      alert(action.error.message);
    });

    builder.addCase(getShortSlotsThuk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getShortSlotsThuk.fulfilled, (state, action) => {
      state.loading = false;
      state.getShortSlots = action.payload;
    });
    builder.addCase(getShortSlotsThuk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      alert(action.error.message);
    });

    builder.addCase(getLongSlotsThuk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLongSlotsThuk.fulfilled, (state, action) => {
      state.loading = false;
      state.getLongSlots = action.payload;
    });
    builder.addCase(getLongSlotsThuk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      alert(action.error.message);
    });

    builder.addCase(getAllBookings.pending, (state) => { });
    builder.addCase(getAllBookings.fulfilled, (state, action) => {
      state.loading = false;
      state.allBookings = action.payload;
    });
    builder.addCase(getAllBookings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(Booking.pending, (state, action) => {
      state.btnLoading = true;
    });
    builder.addCase(Booking.fulfilled, (state, action) => {
      toast.success(action.payload.message);
      state.booking = action.payload;
      state.btnLoading = false;
    });
    builder.addCase(Booking.rejected, (state, action) => {
      state.error = action.error.message;
      state.btnLoading = false;
    });

    builder.addCase(getOneBooking.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOneBooking.fulfilled, (state, action) => {
      state.loading = false;
      state.getOneBooking = action.payload;
    });
    builder.addCase(getOneBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getOneRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOneRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.getOneRequest = action.payload;
    });
    builder.addCase(getOneRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getAllRequests.pending, (state) => { });
    builder.addCase(getAllRequests.fulfilled, (state, action) => {
      state.loading = false;
      state.allRequests = action.payload;
    });
    builder.addCase(getAllRequests.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //lockks
    builder.addCase(getAllSmartLocks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSmartLocks.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllSmartLocks = action.payload;
    });
    builder.addCase(getAllSmartLocks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getLockStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLockStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.getLockStatus = action.payload;
    });
    builder.addCase(getLockStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(lockSmartLock.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(lockSmartLock.fulfilled, (state, action) => {
      state.loading = false;
      state.lockSmartLock = action.payload;
    });
    builder.addCase(lockSmartLock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(unlockSmartLock.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(unlockSmartLock.fulfilled, (state, action) => {
      state.loading = false;
      state.unlockSmartLock = action.payload;
    });
    builder.addCase(unlockSmartLock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getSmartLockLogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSmartLockLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.getSmartLockLogs = action.payload;
    });
    builder.addCase(getSmartLockLogs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(lockByBooking.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(lockByBooking.fulfilled, (state, action) => {
      state.loading = false;
      state.lockByBooking = action.payload;
    });
    builder.addCase(lockByBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(unlockByBooking.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(unlockByBooking.fulfilled, (state, action) => {
      state.loading = false;
      state.unlockByBooking = action.payload;
    });
    builder.addCase(unlockByBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  resetOneBooking,
  resetBookingError,
  resetBooking,
  setPageBooking,
  setStartBookingTime,
  setEndBookingTime,
  setStartReqTime,
  setEndReqTime,
  setBookingStatus,
  resetBookingState,
  resetRequestState,
  setBookingKeyword,
  setRequestKeyword,
  setReqStatus,
  resetReqStatus,
  resetStatus,
  setPageRequest,
} = BookedSlice.actions;

export default BookedSlice.reducer;
