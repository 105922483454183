import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { LocationIcon, Star, StarFilled } from "../components/svg/Svg";
import { FaRegCopy } from "react-icons/fa";
import { IoEyeOutline, IoLockOpen } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { AddFavKey, deleteKey, getAllKeys, RemoveFavKey } from "../../../slices/keys/thunk";
import DeleteModal from "../components/DeleteModal";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { FormatedDate } from "../../../helpers/date_helper";
import { debounce } from "lodash";
import {
  lockSmartLock,
  unlockSmartLock,
} from "../../../slices/bookedKeys/thunk";
import SmartButtonGroup from "../components/SmartButtonGroup";
import {
  resetAddKey,
  resetdeleteKey,
  resetError,
} from "../../../slices/keys/reducer";
import WarningAlert from "../components/WarningAlert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../../Components/Common/Pagination";
import { addKeyFavData } from "../../../helpers/fakebackend_helper";

registerPlugin(FilePondPluginImagePreview);

const Key = () => {
  const [isCopied, setIsCopied] = useState({});
  const [selectedStars, setSelectedStars] = useState({});

  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [page, setPage] = useState(1);

  const loading = useSelector((state) => state.KeySlice.loading);
  const errorRes = useSelector((state) => state.KeySlice.error);

  const dispatch = useDispatch();
  const allKeysRes = useSelector((state) => state.KeySlice.allKeys);
  const deleteKeysRes = useSelector((state) => state.KeySlice.deleteKey);
  const addKeyRes = useSelector((state) => state.KeySlice.addKey);

  const [baseUrl, setBaseUrl] = useState("");

  const [openerStatus, setOpenerStatus] = useState([]);
  const [smartLockStatus, setSmartLockStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedKey, setSelectedKey] = useState("all");

  const perPageData = 10;

  const handleToggle = (id) => {
    setSelectedStars((prevState) => {
      const isStarSelected = !prevState[id];
      if (isStarSelected) {
        dispatch(AddFavKey(id));
      } else {
        dispatch(RemoveFavKey(id));
      }
      return {
        ...prevState,
        [id]: isStarSelected,
      };
    });
  };
 
  useEffect(() => {
    if (allKeysRes?.data) {
      const newOpenerStatus = {};
      const newSmartLockStatus = {};
      const starsState = {};

      allKeysRes.data.forEach((key) => {
        newOpenerStatus[key?.key?._id] = key?.openerStatus;
        newSmartLockStatus[key?.key?._id] = key?.smartLockStatus;
        starsState[key?.key?._id] = key?.isFavorite;
      });
      setOpenerStatus(newOpenerStatus);
      setSmartLockStatus(newSmartLockStatus);
      setSelectedStars(starsState);

    }
  }, [allKeysRes]);

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, []);

  const handleNextKeysApi = () => {
    setPage((val) => val + 1);
    let params = {
      page: page + 1,
      limit: perPageData,
    };
    if (searchQuery) {
      params.keyword = searchQuery;
    }
    dispatch(getAllKeys(params));
  };

  const handlePrevKeysApi = () => {
    setPage((val) => val - 1);
    let params = {
      page: page - 1,
      limit: perPageData,
    };
    if (searchQuery) {
      params.keyword = searchQuery;
    }
    dispatch(getAllKeys(params));
  };

  useEffect(() => {
    let params = {};

    if (searchQuery) {
      params.keyword = searchQuery;
    } else {
      params.page = page;
      params.limit = perPageData;
    }

    dispatch(getAllKeys(params));
    dispatch(resetdeleteKey());
    dispatch(resetAddKey());
    setDeleteModal(false);
  }, [deleteKeysRes.success, addKeyRes.message, searchQuery]);

  useEffect(() => {
    if (deleteKeysRes && deleteKeysRes.success) {
      toast(deleteKeysRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    } else if (addKeyRes && addKeyRes.success) {
      toast(addKeyRes.message, {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    }
  }, [addKeyRes.success]);

  const handleCopyClick = (link, keyId, type) => {
    const copyKey = `${keyId}_${type}`;
    navigator.clipboard.writeText(link).then(() => {
      setIsCopied({ ...isCopied, [copyKey]: true });
      setTimeout(() => setIsCopied({ ...isCopied, [copyKey]: false }), 500);
    });
  };

  const handleDeleteKey = (id) => {
    setDeletedId(id);
    setDeleteModal(true);
  };
  const confirmDelete = () => {
    if (deletedId !== null) {
      dispatch(deleteKey(deletedId));
      setDeleteModal(false);
      setDeletedId(null);
    }
  };

  const handleSearchChange = debounce((text) => {
    setPage(1)
    setSearchQuery(text);
  }, 500);

  const handleSmartLockButtonClick = async (buttonName, key) => {
    try {
      if (buttonName === "lock") {
        const res = await dispatch(lockSmartLock(key?.key?.smartlockId));
        if (res && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?.key?._id] = "locked";
          setSmartLockStatus(newStatus);
        }
      } else if (buttonName === "unlock") {
        const res = await dispatch(unlockSmartLock(key?.key?.smartlockId));
        if (res && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?.key?._id] = "unlocked";
          setSmartLockStatus(newStatus);
        }
      }
    } catch (error) {
      console.error("Error locking smart lock:", error);
    }
  };

  const handleOpenerButtonClick = async (buttonName, key) => {
    try {
      if (buttonName === "lock") {
        const res = await dispatch(lockSmartLock(key?.key?.openerId));
        if (res && res.payload?.success) {
          const newStatus = { ...openerStatus };
          newStatus[key?.key?._id] = "locked";
          setOpenerStatus(newStatus);
        }
      } else if (buttonName === "unlock") {
        const res = await dispatch(unlockSmartLock(key?.key?.openerId));
        if (res && res.payload?.success) {
          const newStatus = { ...openerStatus };
          newStatus[key?.key?._id] = "unlocked";
          setOpenerStatus(newStatus);
        }
      }
    } catch (error) {
      console.error("Error locking smart lock:", error);
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const base = getBaseUrl(currentUrl);
    setBaseUrl(base);
  }, []);

  function getBaseUrl(url) {
    try {
      const parsedUrl = new URL(url);
      return `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.port ? `:${parsedUrl.port}` : ""
        }`;
    } catch (error) {
      console.error("Invalid URL", error);
      return null;
    }
  }

  const paginatedData = allKeysRes?.data?.slice(
    (currentPage - 1) * perPageData,
    currentPage * perPageData
  );

  return (
    <>
      <div className="page-content">
        {errorRes && errorRes ? <WarningAlert msg={errorRes} /> : null}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={confirmDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
        <ToastContainer autoClose={2000} limit={1} />

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody >
                <div className="d-flex align-items-center">
                  <h6 className="card-title mb-0 flex-grow-1 fw-bold">
                    Your Keys
                  </h6>
                </div>
                <Row className="mt-3 gy-3">
                  <Col xxl={12} md={6}>
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search bg-light border-light"
                        id="searchJob"
                        autoComplete="off"
                        placeholder="Search Your Keys..."
                        onChange={(e) => handleSearchChange(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  <Col className="col-xl-12 d-none" id="found-job-alert">
                    <div
                      className="alert alert-success mb-0 text-center"
                      role="alert"
                    >
                      <strong id="total-result">253</strong> jobs found
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {loading ? (
            <div className="d-flex justify-content-center my-3">
              <Spinner
                animation="border"
                role="status"
                className="text-success"
                style={{ width: "2rem", height: "2rem" }}
              />
            </div>
          ) : (
            <div>
              <Col lg={12}>
                {paginatedData && paginatedData.length > 0 ? (
                  paginatedData?.map((key) => (
                    <div
                      key={key?.key?._id}
                      className="card task-box"
                      id={`key-${key.key?._id}`}
                    >
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <div className="mb-3">
                            <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title">
                              <div className="d-block" id="task-name">
                                {key?.key?.name}
                              </div>
                            </h6>
                          </div>
                          <div className="d-flex justify-content-around">
                            <div className="me-3 cursor-pointer" onClick={() => handleToggle(key.key?._id)}>{selectedStars[key.key?._id] ? <StarFilled /> : <Star />}
                            </div>
                            <UncontrolledDropdown className="float-end cursor-pointer">
                              <DropdownToggle
                                className="arrow-none"
                                tag="a"
                                color="white"
                              >
                                <i className="ri-more-fill"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <Link
                                  to={`/view-key/${key.key?._id}`}
                                  className="Link"
                                >
                                  <DropdownItem className="edittask-details">
                                    View
                                  </DropdownItem>
                                </Link>
                                <Link to={`/edit-key/${key.key?._id}`}>
                                  <DropdownItem className="edittask-details">
                                    Edit
                                  </DropdownItem>
                                </Link>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>

                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div className="d-flex">
                            <LocationIcon width={20} height={20} />
                          </div>
                          <div className="d-flex gap-2 text-center">
                            <h6 className="text-muted mt-1">Address: </h6>
                            <h6 className="mt-1">{key?.key?.address}</h6>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col">
                            {key?.key?.long_term_booking_enabled && (
                              <div className="row mb-3">
                                <div>
                                  <h6 className="text-muted mb-0 col-sm-6">
                                    Long Term Key Booking Link:
                                  </h6>
                                  <div className="d-flex row align-items-center mt-1">
                                    <div className="col-sm-4 col-10">
                                      <input
                                        type="text"
                                        value={`${baseUrl}/book-a-key/long-term/${key?.key?._id}`}
                                        readOnly
                                        className="form-control"
                                        style={{
                                          flex: 1,
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-1 col-2 d-sm-flex">
                                      <FaRegCopy
                                        style={{ cursor: "pointer" }}
                                        size={20}
                                        onClick={() =>
                                          handleCopyClick(
                                            `${baseUrl}/book-a-key/long-term/${key?.key?._id}`,
                                            key.key?._id,
                                            "longTerm"
                                          )
                                        }
                                      />
                                      {isCopied[`${key.key._id}_longTerm`] && (
                                        <div
                                          className="ml-3"
                                          style={{ color: "green" }}
                                        >
                                          Copied!
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {key?.key?.short_term_booking_enabled && (
                              <div className="row mb-3">
                                <div>
                                  <h6 className="text-muted mb-0 col-sm-6">
                                    Short Term Key Booking Link:
                                  </h6>
                                  <div className="d-flex row align-items-center mt-1">
                                    <div className="col-sm-4 col-10">
                                      <input
                                        type="text"
                                        value={`${baseUrl}/book-a-key/short-term/${key.key?._id}`}
                                        readOnly
                                        className="form-control"
                                        style={{
                                          flex: 1,
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-1 col-2 d-sm-flex">
                                      <FaRegCopy
                                        style={{ cursor: "pointer" }}
                                        size={20}
                                        onClick={() =>
                                          handleCopyClick(
                                            `${baseUrl}/book-a-key/short-term/${key.key?._id}`,
                                            key.key?._id,
                                            "shortTerm"
                                          )
                                        }
                                      />
                                      {isCopied[`${key.key?._id}_shortTerm`] && (
                                        <div
                                          className="ml-3"
                                          style={{ color: "green" }}
                                        >
                                          Copied!
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          {key?.smartLockStatus && (
                            <div className="col-md-6 mt-sm-3">
                              <div className="d-flex gap-3 align-items-center">
                                <h6 className="font-weight-bold text-lg">
                                  Smart Lock{" "}
                                </h6>
                                <h6 className="text-lg text-muted">
                                  status:{" "}
                                  <span className="orange-icon">
                                    {key?.smartLockStatus}
                                  </span>
                                </h6>
                              </div>
                              <div className="">
                                {key && (
                                  <SmartButtonGroup
                                    handleSmartLockButtonClick={
                                      handleSmartLockButtonClick
                                    }
                                    newKey={key}
                                    smartLockStatus={smartLockStatus}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          {key?.openerStatus && (
                            <div className="col-md-6 mt-sm-3">
                              <div className="d-flex gap-3 align-items-center">
                                <h6 className="font-weight-bold text-lg">
                                  Opener{" "}
                                </h6>
                                <h6 className="text-lg text-muted">
                                  status:{" "}
                                  <span className="orange-icon">
                                    {key?.openerStatus}
                                  </span>
                                </h6>
                              </div>
                              <div className="">
                                {key && (
                                  <SmartButtonGroup
                                    handleSmartLockButtonClick={
                                      handleOpenerButtonClick
                                    }
                                    newKey={key}
                                    smartLockStatus={openerStatus}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <div className="orange-icon d-flex mt-3 gap-1 align-items-center">
                  <IoLockOpen />
                  <span>Lock is Close by: Andress Istvan,12.05 Pm </span>
                </div> */}
                      </CardBody>
                      <div className="card-footer border-top-dashed">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <span className="text-muted">
                              <i className="ri-time-line align-bottom">
                                {FormatedDate(key?.key?.createdAt)}
                              </i>
                            </span>
                          </div>
                          <div className="flex-shrink-0">
                            <ul className="link-inline mb-0">
                              <li className="list-inline-item">
                                <Link
                                  to={`/view-key/${key.key?._id}`}
                                  className="Link"
                                >
                                  <div>
                                    <Button
                                      variant="outline"
                                      className="d-flex align-items-center justify-content-center white-button w-100 w-sm-50"
                                    >
                                      <IoEyeOutline className="me-1" />
                                      <span>View Key</span>
                                    </Button>
                                  </div>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Button
                                  color="danger"
                                  variant="outline"
                                  onClick={() => handleDeleteKey(key.key?._id)}
                                  className="w-100 d-flex align-items-center justify-content-center white-button hover-red"
                                >
                                  <RiDeleteBin6Line className="me-2" />
                                  Delete Key
                                </Button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex justify-content-center">
                    <div>
                      <h4 className="text-white p-2 bg-danger rounded shadow">
                        No Keys found
                      </h4>
                    </div>
                  </div>
                )}
              </Col>
              <Pagination
                data={allKeysRes?.data}
                currentPage={page}
                setCurrentPage={setCurrentPage}
                perPageData={perPageData}
                handleNextKeysApi={handleNextKeysApi}
                handlePrevKeysApi={handlePrevKeysApi}
                customPageSize={10}
                total={10}
              />
            </div>
          )}

        </Row>
      </div>
    </>
  );
};

export default Key;
