import { createSlice } from "@reduxjs/toolkit";
import { AddNote, EditNote, getNotes, deleteKeyNote } from "./thunk";

export const initialState = {
  addNote: [],
  editNote: {},
  getNotes: [],
  deleteKeyNote: {},
};

const SwipeNotesSlice = createSlice({
  name: "swipeNotes",
  initialState,
  reducers: {
    resetEditNote(state) {
      state.editNote = {}
    },
    resetaddNote(state) {
      state.addNote = []
    },
    resetdeleteKeyNote(state) {
      state.deleteKeyNote = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddNote.fulfilled, (state, action) => {
      state.addNote = action.payload;
    });
    builder.addCase(AddNote.rejected, (state, action) => {
      state.error = action.error.message;
      alert(action.error.message);
    });
    builder.addCase(getNotes.fulfilled, (state, action) => {
      state.getNotes = action.payload;
    });
    builder.addCase(getNotes.rejected, (state, action) => {
      state.error = action.error.message;
      alert(action.error.message);
    });
    builder.addCase(deleteKeyNote.fulfilled, (state, action) => {
      state.deleteKeyNote = action.payload;
    });
    builder.addCase(deleteKeyNote.rejected, (state, action) => {
      state.error = action.error.message;
      alert(action.error.message);
    });
    builder.addCase(EditNote.fulfilled, (state, action) => {
      state.editNote = action.payload;
    });
    builder.addCase(EditNote.rejected, (state, action) => {
      state.error = action.error.message;
      alert(action.error.message);
    });
  },
});

export const {resetEditNote,resetaddNote,resetdeleteKeyNote} = SwipeNotesSlice.actions;

export default SwipeNotesSlice.reducer;
