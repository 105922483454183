import { createSlice } from "@reduxjs/toolkit";
import {
  getAllKeys,
  getOneKey,
  AddKey,
  updateOneKey,
  AddKeysInKeyGroup,
  RemoveKeysFromKeyGroup,
  deleteKey,
  AddFavKey,
  RemoveFavKey,
} from "./thunk";

export const initialState = {
  allKeys: [],
  oneKey: [],
  addKey: {},
  addKeysInKeyGroup: [],
  removeKeysFromKeyGroup: {},
  updateKey: [],
  deleteKey: {},
  addFav:{},
  removeFav:{},
  page: 1,
  loading: false, // Add loading state
  error: null, // Add error state to handle errors
};

const KeySlice = createSlice({
  name: "keys",
  initialState,
  reducers: {
    setPageKey(state, action) {
      state.page = action.payload;
    },
    resetOneKey: (state) => {
      state.oneKey = [];
    },
    resetAddKeysInKeyGroup: (state) => {
      state.addKeysInKeyGroup = [];
    },
    resetRemoveKeysFromKeyGroup: (state) => {
      state.removeKeysFromKeyGroup = {};
    },
    resetdeleteKey: (state) => {
      state.deleteKey = {};
    },
    resetAddKey: (state) => {
      state.addKey = {};
    },
    resetUpdateKey: (state) => {
      state.updateKey = {};
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RemoveKeysFromKeyGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(RemoveKeysFromKeyGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.removeKeysFromKeyGroup = action.payload;
    });
    builder.addCase(RemoveKeysFromKeyGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(AddKeysInKeyGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddKeysInKeyGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.addKeysInKeyGroup = action.payload;
    });
    builder.addCase(AddKeysInKeyGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getAllKeys.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllKeys.fulfilled, (state, action) => {
      state.loading = false;
      state.allKeys = action.payload;
    });
    builder.addCase(getAllKeys.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getOneKey.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOneKey.fulfilled, (state, action) => {
      state.loading = false;
      state.oneKey = action.payload;
    });
    builder.addCase(getOneKey.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(AddKey.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddKey.fulfilled, (state, action) => {
      state.loading = false;
      state.addKey = action.payload;
    });
    builder.addCase(AddKey.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateOneKey.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOneKey.fulfilled, (state, action) => {
      state.loading = false;
      state.updateKey = action.payload;
    });
    builder.addCase(updateOneKey.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteKey.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteKey.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteKey = action.payload;
    });
    builder.addCase(deleteKey.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(AddFavKey.fulfilled, (state, action) => {
      state.addFav = action.payload;
    });
    builder.addCase(AddFavKey.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(RemoveFavKey.fulfilled, (state, action) => {
      state.removeFav = action.payload;
    });
    builder.addCase(RemoveFavKey.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export const {
  resetOneKey,
  resetError,
  resetAddKey,
  resetdeleteKey,
  resetUpdateKey,
  resetAddKeysInKeyGroup,
  resetRemoveKeysFromKeyGroup,
  setPageKey,
} = KeySlice.actions;

export default KeySlice.reducer;
