import { createSlice } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllKeyGroups,
  AddKeyGroup,
  getKeyGroupById,
  UpdateKeyGroupById,
  deleteKeyGroup,
} from "./thunk";

export const initialState = {
  allKeyGroups: [],
  addKeyGroup: [],
  getKeyGroup: {},
  updateKeyGroup: {},
  deleteKeyGroupState: {},
  page:1,
  loading: false,
  error: null,
};

const KeyGroupSlice = createSlice({
  name: "keyGroups",
  initialState,
  reducers: {
    setPage(state, action) {
      state.page = action.payload
    },
    resetgetKeyGroup: (state) => {
      state.getKeyGroup = {};
    },
    resetAddKeyGroup: (state) => {
      state.addKeyGroup = [];
    },
    resetDeleteKeyGroup: (state) => {
      state.deleteKeyGroupState = {};
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllKeyGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllKeyGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.allKeyGroups = action.payload;
      })
      .addCase(getAllKeyGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(AddKeyGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddKeyGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.addKeyGroup = action.payload;
      })
      .addCase(AddKeyGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getKeyGroupById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKeyGroupById.fulfilled, (state, action) => {
        state.loading = false;
        state.getKeyGroup = action.payload;
      })
      .addCase(getKeyGroupById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(UpdateKeyGroupById.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateKeyGroupById.fulfilled, (state, action) => {
        state.loading = false;
        state.updateKeyGroup = action.payload;
      })
      .addCase(UpdateKeyGroupById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteKeyGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteKeyGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteKeyGroupState = action.payload;
      })
      .addCase(deleteKeyGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetgetKeyGroup,setPage, resetError,resetDeleteKeyGroup, resetAddKeyGroup } =
  KeyGroupSlice.actions;

export default KeyGroupSlice.reducer;
