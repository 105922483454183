import React from "react";
import { Button, ButtonGroup } from "reactstrap";

const SmartButtonGroup = ({
  handleSmartLockButtonClick,
  newKey,
  smartLockStatus,
}) => {
  return (
    <div className="">
      <ButtonGroup role="group" className="w-100 border">
        <Button
          disabled={
            smartLockStatus[newKey?.key?._id || newKey?.booking?._id] ===
            "locked"
          }
          color={
            smartLockStatus[newKey?.key?._id || newKey?.booking?._id] ===
            "locked"
              ? "#15294E"
              : "light"
          }
          className="w-50"
          onClick={() => handleSmartLockButtonClick("lock", newKey)}
          style={{
            background:
              smartLockStatus[newKey?.key?._id || newKey?.booking?._id] ===
              "locked"
                ? "#15294E"
                : "white",
            color:
              smartLockStatus[newKey?.key?._id || newKey?.booking?._id] ===
              "locked"
                ? "white"
                : "#15294E",
            borderColor: "gray",
          }}
        >
          Lock
        </Button>
        <Button
          disabled={
            smartLockStatus[newKey?.key?._id || newKey?.booking?._id] === "unlocked"
          }
          style={{
            background:
              smartLockStatus[newKey?.key?._id || newKey?.booking?._id] ===
              "unlocked"
                ? "#15294E"
                : "white",
            color:
              smartLockStatus[newKey?.key?._id || newKey?.booking?._id] ===
              "unlocked"
                ? "white"
                : "#15294E",
            borderColor: "gray",
          }}
          className="w-50"
          onClick={() => handleSmartLockButtonClick("unlock", newKey)}
        >
          Open
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default SmartButtonGroup;
