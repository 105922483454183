import React from "react";
import "filepond/dist/filepond.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import { BASE_URL } from "../../../helpers/url_helper";

registerPlugin(FilePondPluginImagePreview);

const FilePondComp = ({ files, setFiles, setFileIds }) => {
  const loadHandler = (source, load, error, progress, abort, headers) => {
    fetch(new Request(source))
      .then((response) => response.blob())
      .then(load)
      .catch((err) => {
        console.error("Image loading failed: ", err);
        abort();
      });
  };

  const processHandler = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    const authData = JSON.parse(localStorage.getItem("authUser"));
    const formData = new FormData();
    formData.append("file", file, file.name);
    fetch(`${BASE_URL}/api/media`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authData.token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setFileIds((prevFileIds) => [...prevFileIds, response.data?._id]);
        load(response.data._id);
      })
      .catch((err) => {
        console.error("Upload failed:", err);
        error("Upload failed");
      });

    return {
      abort: () => abort(),
    };
  };

  const handleRemoveFile = (error, file) => {
    setFileIds((prevFileIds) =>
      prevFileIds.filter((id) => id !== file.serverId)
    );
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Add Images</h5>
        </CardHeader>
        <CardBody>
          <FormGroup style={{ backgroundColor: "white" }}>
            <div className="filepond-wrapper">
              <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={true}
                server={{
                  load: loadHandler,
                  process: processHandler,
                }}
                name="files"
                className="filepond filepond-input-multiple"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                onremovefile={handleRemoveFile}
              />
            </div>
          </FormGroup>
        </CardBody>
      </Card>
    </div>
  );
};

export default FilePondComp;
