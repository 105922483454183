import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllKeyGroupsData,
  AddKeyGroupData,
  getKeyGroupByIdData,
  UpdateKeyGroupByIdData,
  deleteKeyGroupByIdData,
} from "../../helpers/fakebackend_helper";

export const getAllKeyGroups = createAsyncThunk(
  "keyGroups/getAllKeyGroupsData",
  async (params) => {
    try {
      const response = getAllKeyGroupsData(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const AddKeyGroup = createAsyncThunk(
  "keyGroups/AddKeyGroup",
  async (data) => {
    try {
      const response = AddKeyGroupData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getKeyGroupById = createAsyncThunk(
  "keyGroups/getKeyGroupById",
  async (data) => {
    try {
      const response = getKeyGroupByIdData(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteKeyGroup = createAsyncThunk(
  "keyGroups/deleteKeyGroup",
  async (ID) => {
    try {
      const response = deleteKeyGroupByIdData(ID);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const UpdateKeyGroupById = createAsyncThunk(
  "keyGroups/UpdateKeyGroupById",
  async ({ values, ID }) => {
    try {
      const response = UpdateKeyGroupByIdData(values, ID);
      return response;
    } catch (error) {
      return error;
    }
  }
);
