import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AddNote, EditNote } from "../../../slices/swipeNotes/thunk";
import MultiSelector from "./MultiSelector";
import { AddKeysInKeyGroup } from "../../../slices/keys/thunk";
import FilePondComp from "./FilePondComp";

const NoteModal = ({ note, setNote, label, noteData, ID, name }) => {
  const [content, setContent] = useState(noteData?.content || "");
  const [selectedIds, setSelectedIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileIds, setFileIds] = useState(noteData?.mediaIds || []);

  const dispatch = useDispatch();

  useEffect(() => {
    setContent(noteData?.content || "");
    // Initialize fileIds with existing mediaIds from noteData
    if (noteData?.mediaIds) {
      setFileIds(noteData.mediaIds);
    }
  }, [noteData]);

  const handleChange = (e) => {
    setContent(e.target.value);
  };

  const handleSave = () => {
    // Combine previous mediaIds with new fileIds, ensuring no duplicates
    const combinedMediaIds = Array.from(
      new Set([...(noteData?.mediaIds || []), ...fileIds])
    );
    const data = { content, keyId: ID, mediaIds: fileIds };
    const keyGroupData = { content, keyGroupId: ID, mediaIds: fileIds };
    const editedData = {
      content,
      ID: noteData?._id,
      mediaIds: combinedMediaIds,
    };
    const keyData = { keyIds: selectedIds, keyGroupId: ID };

    if (name === "keyGroup") {
      if (label === "Add Note") {
        dispatch(AddNote(keyGroupData));
        setNote(false);
      } else if (label === "Edit Note") {
        dispatch(EditNote({ values: editedData.content, ID: editedData.ID }));
        setNote(false);
      } else if (label === "Add Keys") {
        dispatch(AddKeysInKeyGroup(keyData));
        setNote(false);
      }
    } else {
      if (label === "Add Note") {
        dispatch(AddNote(data));
        setNote(false);
        setFileIds([]);
      } else if (label === "Edit Note") {
        dispatch(
          EditNote({
            values: editedData.content,
            ID: editedData.ID,
            mediaIds: editedData.mediaIds,
          })
        );
        setNote(false);
      }
    }
  };

  // Add keys
  const handleSelectedKey = (data) => {
    const selectedValues = data.map((item) => item?.value);
    setSelectedIds(selectedValues);
  };

  return (
    <div>
      <Modal
        isOpen={note}
        toggle={() => {
          setNote(false);
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            setNote(false);
          }}
        >
          {label}
        </ModalHeader>
        <ModalBody>
          {label !== "Add Keys" && (
            <form>
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  Note:
                </label>
                <textarea
                  className="form-control"
                  id="message-text"
                  value={content}
                  onChange={handleChange}
                  rows="2"
                ></textarea>
                {label !== "See Note" && (
                  <div>
                    <FilePondComp
                      files={files}
                      setFiles={setFiles}
                      setFileIds={setFileIds}
                      name="files"
                    />
                  </div>
                )}
              </div>
            </form>
          )}
          {label === "Add Keys" && (
            <div>
              <div className="mb-3">
                <Label className="form-label" htmlFor="select-keys-input">
                  Select Keys
                </Label>
                <MultiSelector
                  handleSelectedFnc={handleSelectedKey}
                  name="keyIDs"
                  placeholder={"Select Keys"}
                />
              </div>
            </div>
          )}
          {noteData?.mediaIds?.map((img, index) => (
            <figure className="figure mb-0" key={img?.url || index}>
              <img
                src={img?.url}
                className="figure-img img-fluid rounded"
                style={{
                  maxWidth: "100px",
                  width: "100%",
                  marginRight: "5px",
                }}
                alt="image"
              />
            </figure>
          ))}
        </ModalBody>

        {label !== "See Note" && (
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setNote(false);
              }}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={handleSave}>
              {label}
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default NoteModal;
