import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap";
import FilePondComp from "../components/FilePondComp";
import MultiSelector from "../components/MultiSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  AddKeyGroup,
  UpdateKeyGroupById,
  getKeyGroupById, 
} from "../../../slices/keyGroups/thunk";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetAddKeyGroup,
  resetgetKeyGroup,
} from "../../../slices/keyGroups/reducer";
import WarningAlert from "../components/WarningAlert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddKeyGroups = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ID } = useParams();

  const getAllKeysData = useSelector((state) => state.KeySlice.allKeys.data);

  // const getOneKeyRes = useSelector(
  //   (state) => state.KeyGroupsSlice.getKeyGroup.data
  // );
  const errorRes = useSelector((state) => state.KeyGroupsSlice.error);
  const AddKeyGroupRes = useSelector(
    (state) => state.KeyGroupsSlice.addKeyGroup
  );
 
  // useEffect(() => {
  //   dispatch(resetgetKeyGroup());
  //   if (ID) {
  //     dispatch(getKeyGroupById(ID));
  //   }
  // }, [ID, dispatch]);

  useEffect(() => {
    if (AddKeyGroupRes && AddKeyGroupRes?.success) {
      navigate("/all-keygroups");
    }
    if (errorRes) {
      toast.error(errorRes);
    }
  }, [AddKeyGroupRes?.success, errorRes]);
 
  // useEffect(() => {
  //   if (ID && getOneKeyRes) {
  //     setName(getOneKeyRes.name || ""); 
  //     setSelectedData(getOneKeyRes?.keyList);
  //     setAddress(getOneKeyRes.address || "");
  //     setDescription(getOneKeyRes.description || "");
  //     setFileIds(getOneKeyRes.mediaIds?.map((data) => data._id) || []);
  //     setSelectedIds(getOneKeyRes.keyIDs || []);
  //   }
  // }, [ID, getOneKeyRes]);

  const handleSelectedKey = (data) => {
    const selectedValues = data.map((item) => item?.value);
    setSelectedIds(selectedValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationErrors = {};
    if (!name) validationErrors.name = "Please Enter Key Name";
    if (!address) validationErrors.address = "Please Enter Key Address";
    if (!description) validationErrors.description = "Please Enter Description";
    // if (fileIds.length === 0)
    //   validationErrors.mediaIds = "Please upload at least one image";
    setErrors(validationErrors);
 
    if (Object.keys(validationErrors).length === 0) {
      const values = {
        name,
        address,
        description,
        mediaIds: fileIds,
        keyIDs: selectedIds,
      };

      if (ID) {
        dispatch(UpdateKeyGroupById({ values, ID })).then(() => {
          navigate(`/view-keygroup/${ID}`);
        });
      } else {
        dispatch(AddKeyGroup(values)).then(() => {
          navigate("/all-keygroups");
        });
      }
    }
  };
 
  return (
    <div className="page-content">
      {errorRes && errorRes ? <WarningAlert msg={errorRes} /> : null}
      <ToastContainer autoClose={2000} limit={1} />
      <Row>
        <Col>
          <div>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Add Key Chain</h5>
              </CardHeader>
              <CardBody>
                {errors.mediaIds && (
                  <FormFeedback className="d-block" type="invalid">
                    {errors.mediaIds}
                  </FormFeedback>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <FilePondComp
                      files={files}
                      setFiles={setFiles}
                      setFileIds={setFileIds}
                      name="files"
                    />
                    {/* {getOneKeyRes &&
                      getOneKeyRes?.mediaIds?.map((img) => (
                        <figure className="figure mb-0" key={img?._id}>
                          <img
                            src={img?.url}
                            className="figure-img img-fluid rounded me-2"
                            style={{ maxWidth: "200px", width: "100%" }}
                            alt="..."
                          />
                        </figure>
                      ))} */}

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="key-name-input">
                        Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-name-input"
                        placeholder="Enter Keychain Name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        invalid={!!errors.name}
                      />
                      {errors.name && (
                        <FormFeedback type="invalid">
                          {errors.name}
                        </FormFeedback>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="key-address-input">
                      Address
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="key-address-input"
                      placeholder="Enter Address"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      invalid={!!errors.address}
                    />
                    {errors.address && (
                      <FormFeedback type="invalid">
                        {errors.address}
                      </FormFeedback>
                    )}
                  </div>
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="key-description-input"
                    >
                      Description
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="key-description-input"
                      placeholder="Enter Description"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      invalid={!!errors.description}
                    />
                    {errors.description && (
                      <FormFeedback type="invalid">
                        {errors.description} 
                      </FormFeedback>
                    )}
                  </div>
                  {!ID && (
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="select-keys-input">
                        Select Keys
                      </Label>
                      <MultiSelector
                        handleSelectedFnc={handleSelectedKey}
                        selectedData={selectedData}
                        name="keyIDs"
                        placeholder={"Select Keys"}
                      />
                      {errors.keyIDs && (
                        <FormFeedback className="d-block" type="invalid">
                          {errors.keyIDs}
                        </FormFeedback>
                      )}
                    </div>
                  )}

                  <div className="text-end mb-3 mt-4">
                    <button
                      type="submit"
                      className="blue-button py-1 px-4 border-none"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddKeyGroups;
