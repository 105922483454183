import { createSlice } from "@reduxjs/toolkit";
import { getAllKeys } from "./thunk";

export const initialState = {
  allKeys: [],
};

const AboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllKeys.fulfilled, (state, action) => {
      state.allKeys = action.payload;
    });

    builder.addCase(getAllKeys.rejected, (state, action) => {
      state.error = action.error.message;
      alert(action.error.message);
    });
  },
});

export const {} = AboutSlice.actions;

export default AboutSlice.reducer;
