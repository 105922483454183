import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import { flattenProduct } from "../../../helpers/date_helper";

const SingleSelect = (props) => {
  const [disselectMulti, setdisselectMulti] = useState(null);
  
  useEffect(() => {
    setdisselectMulti(null);
  }, []);

  useEffect(() => {
    if (props && props.selectedData) {
      let params = {
        id: props.selectedData._id || props.selectedData.value,
        label: props.selectedData.name || props.selectedData.label,
      };
      setdisselectMulti(params);
    }
  }, [props.selectedData]);

  const customStyles = {
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#3762ea",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      backgroundColor: "#405189",
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "white",
      backgroundColor: "#405189",
      ":hover": {
        backgroundColor: "#405189",
        color: "white",
      },
    }),
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="">
            <Select
              value={disselectMulti}
              placeholder={`${props.placeholder}`}
              isMulti={false}
              onChange={(selectedItems) => {
                props.handleSelectedFnc(selectedItems);
                setdisselectMulti(selectedItems);
              }}
              options={flattenProduct(props.data)}
              classNamePrefix="js-example-disabled-multi mb-0"
              styles={customStyles}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SingleSelect;
