import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Spinner, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  Booking,
  getOneBooking,
  lockByBooking,
  unlockByBooking,
} from "../../../slices/bookedKeys/thunk";
import {
  FormatTime,
  FormatedDate,
  FormatedTime,
  formatDateTime,
  getDateTime,
  splitDateTime,
} from "../../../helpers/date_helper";
import {
  resetBooking,
  resetOneBooking,
} from "../../../slices/bookedKeys/reducer";
import SmartButtonGroup from "../components/SmartButtonGroup";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewBookedKey = () => {
  const dispatch = useDispatch();
  const [smartLockStatus, setSmartLockStatus] = useState([]);
  const [openerStatus, setOpenerStatus] = useState([]);
  const [isWithinTimeRange, setIsWithinTimeRange] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  const { ID } = useParams();

  const getOneBokedKeyRes = useSelector(
    (state) => state.BookedSlice.getOneBooking
  );
  const loading = useSelector((state) => state.BookedSlice.loading);
  const btnLoading = useSelector((state) => state.BookedSlice.btnLoading);
  const bookingRes = useSelector((state) => state.BookedSlice.Booking);

  useEffect(() => {
    if (getOneBokedKeyRes?.data) {
      const newOpenerStatus = {};
      const newSmartLockStatus = {};

      newOpenerStatus[getOneBokedKeyRes?.data?.booking?._id] =
        getOneBokedKeyRes?.data?.openerStatus;
      newSmartLockStatus[getOneBokedKeyRes?.data?.booking?._id] =
        getOneBokedKeyRes?.data?.smartLockStatus;
      setOpenerStatus(newOpenerStatus);
      setSmartLockStatus(newSmartLockStatus);
    }
  }, [getOneBokedKeyRes]);

  useEffect(() => {
    dispatch(getOneBooking(ID));
  }, [dispatch])

  useEffect(() => {
    if (bookingRes && bookingRes.success) {
      dispatch(getOneBooking(ID));
      dispatch(resetOneBooking());
    }
  }, [bookingRes])

  const handleBooking = (id, status) => {
    let params = {
      bookingId: id,
      status: status,
    };
    dispatch(Booking(params));
  };

  const handleSmartLockButtonClick = async (buttonName, key) => {
    try {
      if (buttonName === "lock") {
        const res = await dispatch(
          lockByBooking(key?.booking?.keyId?.smartlockId)
        );
        if (res && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?.booking?._id] = "locked";
          setSmartLockStatus(newStatus);
        }
      } else if (buttonName === "unlock") {
        const res = await dispatch(
          unlockByBooking(key?.booking?.keyId?.smartlockId)
        );
        if (res && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?.booking?._id] = "open";
          setSmartLockStatus(newStatus);
        }
      }
    } catch (error) {
      console.error("Error locking smart lock:", error);
    }
  };

  const handleOpenerButtonClick = async (buttonName, key) => {
    try {
      if (buttonName === "lock") {
        const res = await dispatch(
          lockByBooking(key?.booking?.keyId?.openerId)
        );
        if (res && res.payload?.success) {
          const newStatus = { ...openerStatus };
          newStatus[key?.booking?._id] = "locked";
          setOpenerStatus(newStatus);
        }
      } else if (buttonName === "unlock") {
        const res = await dispatch(
          unlockByBooking(key?.booking?.keyId?.openerId)
        );
        if (res && res.payload?.success) {
          const newStatus = { ...openerStatus };
          newStatus[key?.booking?._id] = "open";
          setOpenerStatus(newStatus);
        }
      }
    } catch (error) {
      console.error("Error locking smart lock:", error);
    }
  };

  useEffect(() => {
    if (
      getOneBokedKeyRes &&
      getOneBokedKeyRes.data &&
      getOneBokedKeyRes.data.booking &&
      getOneBokedKeyRes.data.booking.startTime
    ) {
      const startTime = formatDateTime(
        getOneBokedKeyRes?.data?.booking.startTime
      );
      const endTime = formatDateTime(getOneBokedKeyRes?.data?.booking?.endTime);
      const currentTime = getDateTime();

      if (
        startTime.date === currentTime.date &&
        (currentTime.time === startTime.time ||
          currentTime.time === endTime.time ||
          (currentTime.time > startTime.time &&
            currentTime.time < endTime.time)) &&
        getOneBokedKeyRes?.data?.booking?.status === "booked"
      ) {
        setIsWithinTimeRange(true);
      }
      // Check if currentTime.date is between startTime.date and endTime.date
      else if (
        currentTime.date >= startTime.date &&
        currentTime.date <= endTime.date &&
        (currentTime.time === startTime.time ||
          currentTime.time === endTime.time ||
          (currentTime.time > startTime.time &&
            currentTime.time < endTime.time)) &&
        getOneBokedKeyRes?.data?.booking?.status === "booked"
      ) {
        setIsWithinTimeRange(true);
      } else {
        setIsWithinTimeRange(false);
      }

      // Calculate the time remaining if the date is the same
      if (
        startTime.date === currentTime.date &&
        currentTime.time < startTime.time
      ) {
        const startTimeInMs = new Date(`${startTime.date}T${startTime.time}`).getTime();
        const currentTimeInMs = new Date(`${currentTime.date}T${currentTime.time}`).getTime();
        const remainingTime = startTimeInMs - currentTimeInMs;

        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setTimeRemaining({ hours, minutes, seconds });

        // Update the counter every second
        const interval = setInterval(() => {
          const newCurrentTimeInMs = new Date().getTime();
          const newRemainingTime = startTimeInMs - newCurrentTimeInMs;

          if (newRemainingTime <= 0) {
            clearInterval(interval);
            setTimeRemaining({ hours: 0, minutes: 0, seconds: 0 });

            if (
              currentTime.time === startTime.time ||
              currentTime.time === endTime.time ||
              (currentTime.time > startTime.time &&
                currentTime.time < endTime.time)
            ) {
              setIsWithinTimeRange(true);
            }
          } else {
            const newHours = Math.floor(newRemainingTime / (1000 * 60 * 60));
            const newMinutes = Math.floor(
              (newRemainingTime % (1000 * 60 * 60)) / (1000 * 60)
            );
            const newSeconds = Math.floor(
              (newRemainingTime % (1000 * 60)) / 1000
            );
            setTimeRemaining({
              hours: newHours,
              minutes: newMinutes,
              seconds: newSeconds,
            });

            // Check if timeRemaining is 0h 0m 0s
            if (newHours === 0 && newMinutes === 0 && newSeconds === 0) {
              setIsWithinTimeRange(true);
            }
          }
        }, 1000); // Update every second
      } else {
        setTimeRemaining(null);
      }
    }
  }, [getOneBokedKeyRes]);

  return (
    <div className="page-content">
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Key Controls</h5>
        </CardHeader>
        <ToastContainer limit={1} />
        <CardBody>
          {timeRemaining && getOneBokedKeyRes?.data?.booking?.status === "booked" && (
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Time Remaining</h5>
              </CardHeader>
              <CardBody>
                <div className="d-flex justify-content-center">
                  <h6 className="text-lg">
                    {timeRemaining.hours}h {timeRemaining.minutes}m{" "}
                    {timeRemaining.seconds}s
                  </h6>
                </div>
              </CardBody>
            </Card>
          )}
          {isWithinTimeRange && (
            <div className="row">
              {getOneBokedKeyRes?.data?.smartLockStatus && (
                <div className="col-md-6 mt-sm-3">
                  <div className="d-flex gap-3 align-items-center">
                    <h6 className="font-weight-bold text-lg">Smart Lock </h6>
                    <h6 className="text-lg text-muted">
                      status:{" "}
                      <span className="orange-icon">
                        {getOneBokedKeyRes?.data?.smartLockStatus}
                      </span>
                    </h6>
                  </div>
                  <div className="">
                    {getOneBokedKeyRes?.data && (
                      <SmartButtonGroup
                        handleSmartLockButtonClick={handleSmartLockButtonClick}
                        newKey={getOneBokedKeyRes?.data}
                        smartLockStatus={smartLockStatus}
                      />
                    )}
                  </div>
                </div>
              )}
              {getOneBokedKeyRes?.data?.openerStatus && (
                <div className="col-md-6 mt-sm-3">
                  <div className="d-flex gap-3 align-items-center">
                    <h6 className="font-weight-bold text-lg">Opener </h6>
                    <h6 className="text-lg text-muted">
                      status:{" "}
                      <span className="orange-icon">
                        {getOneBokedKeyRes?.data?.openerStatus}
                      </span>
                    </h6>
                  </div>
                  <div className="">
                    {getOneBokedKeyRes?.data && (
                      <SmartButtonGroup
                        handleSmartLockButtonClick={handleOpenerButtonClick}
                        newKey={getOneBokedKeyRes?.data}
                        smartLockStatus={openerStatus}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between ">
            <h5 className="card-title mb-0">Basic Details</h5>
            <span
              className={`badge text-uppercase ${getOneBokedKeyRes?.data?.booking?.status === "cancelled"
                ? "bg-danger"
                : getOneBokedKeyRes?.data?.booking?.status ===
                  "pending_approval"
                  ? "bg-warning"
                  : getOneBokedKeyRes?.data?.booking?.status === "rejected"
                    ? "bg-danger"
                    : "bg-success"
                }`}
            >{getOneBokedKeyRes?.data?.booking?.status}</span>
          </div>
        </CardHeader>
        {loading ? (
          <div className="d-flex justify-content-center my-3">
            <Spinner
              animation="border"
              role="status"
              className="text-success"
              style={{ width: "2rem", height: "2rem" }}
            />
          </div>
        ) : (
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Booked Time</td>
                    <td>
                      {FormatedTime(
                        getOneBokedKeyRes?.data?.booking?.startTime
                      )}{" "}
                      -{" "}
                      {FormatedTime(getOneBokedKeyRes?.data?.booking?.endTime)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Key Name</td>
                    <td>{getOneBokedKeyRes?.data?.booking?.keyId?.name}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Key Flat Passcode</td>
                    <td>
                      {
                        getOneBokedKeyRes?.data?.booking?.keyId
                          ?.keyPasscodeFlatDoor
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Key House Passcode</td>
                    <td>
                      {
                        getOneBokedKeyRes?.data?.booking?.keyId
                          ?.keyPasscodeHouseDoor
                      }
                    </td>
                  </tr>
                  {getOneBokedKeyRes?.data?.booking?.keyId
                    ?.long_term_bookingTimes[0] && (
                      <tr>
                        <td className="fw-medium">Long Term Booking Time</td>
                        <td>
                          {
                            getOneBokedKeyRes?.data?.booking?.keyId
                              ?.long_term_bookingTimes[0].startTime
                          }
                          -
                          {
                            getOneBokedKeyRes?.data?.booking?.keyId
                              ?.long_term_bookingTimes[0].endTime
                          }
                        </td>
                      </tr>
                    )}
                  {getOneBokedKeyRes?.data?.booking?.keyId
                    ?.long_term_offDays && (
                      <tr>
                        <td className="fw-medium">Long Term Off Days</td>
                        <td>
                          {getOneBokedKeyRes?.data?.booking?.keyId?.long_term_offDays
                            .map((date) => FormatedDate(date))
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                  {getOneBokedKeyRes?.data?.booking?.keyId
                    ?.long_term_weekdays && (
                      <tr>
                        <td className="fw-medium">Long Term Week Days</td>
                        <td>
                          {getOneBokedKeyRes?.data?.booking?.keyId?.long_term_weekdays
                            .map((day) => day)
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td className="fw-medium">Contact Number</td>
                    <td>
                      {getOneBokedKeyRes?.data?.booking?.keyId?.phoneNumber}
                    </td>
                  </tr>
                  {getOneBokedKeyRes?.data?.booking?.keyId
                    ?.short_term_bookingTimes[0] && (
                      <tr>
                        <td className="fw-medium">Short Term All Slots</td>
                        <td>
                          {getOneBokedKeyRes?.data?.booking?.keyId?.short_term_bookingTimes
                            .map((time) => `${time.startTime} - ${time.endTime}`)
                            .join(", ")}
                        </td>
                      </tr>
                    )}

                  {getOneBokedKeyRes?.data?.booking?.keyId
                    ?.short_term_offDays &&
                    getOneBokedKeyRes?.data?.booking?.keyId?.short_term_offDays.some(
                      (date) => date !== null
                    ) && (
                      <tr>
                        <td className="fw-medium">Short Term Off Days</td>
                        <td>
                          {getOneBokedKeyRes?.data?.booking?.keyId?.short_term_offDays
                            .filter((date) => date !== null)
                            .map((date) => FormatedDate(date))
                            .join(", ")}
                        </td>
                      </tr>
                    )}

                  {getOneBokedKeyRes?.data?.booking?.keyId
                    ?.short_term_weekdays && (
                      <tr>
                        <td className="fw-medium">Short Term Week Days</td>
                        <td>
                          {getOneBokedKeyRes?.data?.booking?.keyId?.short_term_weekdays
                            .map((day) => day)
                            .join(", ")}
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td className="fw-medium">Smart Lock Id</td>
                    <td>
                      {getOneBokedKeyRes?.data?.booking?.keyId?.smartlockId}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium">User Name</td>
                    <td>
                      {getOneBokedKeyRes?.data?.booking?.userId?.firstName}{" "}
                      {getOneBokedKeyRes?.data?.booking?.userId?.lastName}
                    </td>
                  </tr>
                  {getOneBokedKeyRes?.data?.smartLockStatus && (
                    <tr>
                      <td className="fw-medium">Smart Lock Status</td>
                      <td>{getOneBokedKeyRes?.data?.smartLockStatus} </td>
                    </tr>
                  )}
                  {getOneBokedKeyRes?.data?.openerStatus && (
                    <tr>
                      <td className="fw-medium">Opener Status</td>
                      <td>{getOneBokedKeyRes?.data?.openerStatus}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        )}
      </Card>
      {(getOneBokedKeyRes?.data?.booking?.status === "booked" ||
        getOneBokedKeyRes?.data?.booking?.status === "pending_approval") && (
          <div className="d-flex justify-content-end gap-2 mt-3 me-2 mb-2">
            <Button
              onClick={() =>
                handleBooking(getOneBokedKeyRes?.data?.booking?._id, "cancelled")
              }
              disabled={btnLoading}
              color="danger"
              variant="outline"
              className="d-flex align-items-center justify-content-center hover-red"
            >
              <RiDeleteBin6Line className="me-1" />
              Cancel
            </Button>
          </div>
        )}
    </div>
  );
};

export default ViewBookedKey;
